import CardList from "@/components/Card-list";
import { useEffect, useRef, useState } from "react";
import http from "../http";
import { getTokenURI } from "../utils/ethNft.js";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Boxes from "@/components/home-boxes";
import { useWallet } from "../WalletContext";
import "@/assets/sass/home.scss";
export default function Home() {
  const { wallet, connectWallet, disconnectWallet, clearAppState } =
    useWallet();
  const { t } = useTranslation();
  const navigate = useNavigate();
  let list1Dom = useRef();
  let list2Dom = useRef();
  let renderCall = (dom) => {
    setTimeout(() => {
      $(dom, ":not(has-update)").each((idx, item) => {
        item.style.position = "relative";
        if ($(item).find(".cs-isotop_item").length) {
          $(item)
            .isotope({
              itemSelector: ".cs-isotop_item",
              transitionDuration: "0.60s",
              percentPosition: true,
              masonry: {
                columnWidth: ".cs-grid_sizer",
              },
            })
            .addClass("has-update");
        }
      });
    }, 300);
  };
  let [banner, setBanner] = useState([]);
  useEffect(() => {
    http
      .getnft(
        `/bpt-nft/nft_list?address=${process.env.REACT_APP_GENERAL}&take=10`
      )
      .then(async (_result) => {
        try {
          // 使用 Promise.all 来等待所有异步操作完成
          const data = await Promise.all(
            window._.map(_result.list, async (_item) => {
              // 获取 token URI
              const _nft = await http
                .getnft(
                  `/bpt-nft/nft_info?token_id=${_item.token_id}&address=${_item.contract_address}`
                )
                .then((res) => {
                  return res.info.nfi_info;
                });
              // const _nft = await getTokenURI(
              //   _item.contract_address,
              //   _item.token_id
              // );
              // 获取 JSON 数据
              // const response = await axios.get(_nft.tokenURI);
              // if (!response.data) {
              //   throw new Error(`Failed to fetch ${_nft}`);
              // }
              return {
                ..._item,
                ..._nft,
              };
            })
          );
          setBanner(data);
        } catch (error) {
          console.error("Error fetching NFTs:", error);
        }
      })
      .catch(() => {});
  }, []);
  let bannerDom = useRef();
  useEffect(() => {
    if (banner.length) {
      window.slickInit && window.slickInit(bannerDom.current);
    }
  }, [banner]);
  let toDetail = (item) => {
    localStorage.setItem("detail_" + item.id, JSON.stringify(item));
    navigate("/explore-details?id=" + item.id);
  };
  let categorys = [
    { name: "(주)아****", val: "11.3k", view: "4.1k", item: "340" },
    { name: "(주)시****", val: "11.3k", view: "4.1k", item: "340" },
    { name: "(주)패****", val: "11.3k", view: "4.1k", item: "340" },
    { name: "이**", val: "11.3k", view: "4.1k", item: "340" },
    { name: "한**", val: "11.3k", view: "4.1k", item: "340" },
    { name: "우**", val: "11.3k", view: "4.1k", item: "340" },
    { name: "최**", val: "11.3k", view: "4.1k", item: "340" },
    { name: "주***", val: "11.3k", view: "4.1k", item: "340" },
  ];
  return (
    <>
      {/*End Header Section*/}

      <div className="cs-height_90 cs-height_lg_80"></div>

      {/* Start Hero */}
      <section
        className="cs-hero cs-style4 cs-bg cs-center"
        data-src="/assets/img/home-banner.jpg"
      >
        <div className="container-fluid">
          <div className="cs-hero_in">
            <div className="cs-hero_in_left">
              <div className="cs-hero_text">
                <div>
                  <img src="/assets/img/logo_white.png" alt="" />
                </div>
                <h1
                  className="cs-hero_title cs-white_color GmarketSans weight400"
                  style={{
                    fontSize: "32px",
                    margin: "26px 0 14px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: t("home.innovation_meeting"),
                  }}
                ></h1>
                <div
                  style={{ fontSize: "14px", color: "#999999" }}
                  className="cs-hero_subtitle cs-medium cs-white_color"
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: t("home.new_paradigm") }}
                  ></div>
                </div>

                <div className="cs-hero_btns">
                  <a
                    href="#/bigtech"
                    className="cs-hero_btn cs-style1 cs-color3"
                  >
                    <span>Explore</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="cs-hero_in_right">
              <div className="cs-slider cs-style1 home-banner" ref={bannerDom}>
                <div
                  className="cs-slider_container"
                  data-autoplay="1"
                  data-loop="1"
                  data-speed="100"
                  data-center="1"
                  data-slides-per-view="responsive"
                  data-xs-slides="1"
                  data-sm-slides="3"
                  data-md-slides="3"
                  data-lg-slides="3"
                  data-add-slides="3"
                >
                  <div className="cs-slider_wrapper">
                    {banner.map((item) => (
                      <div className="cs-slide" key={item.id}>
                        <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                          {/* <span className="cs-card_like cs-primary_color">
                          <i className="fas fa-heart fa-fw"></i>
                          2.1K
                        </span> */}
                          <a
                            onClick={() => toDetail(item)}
                            className="cs-card_thumb cs-zoom_effect"
                          >
                            <img
                              src={item.image}
                              alt="Image"
                              className="cs-zoom_item"
                            />
                          </a>
                          <div className="cs-card_info">
                            <a className="cs-avatar">
                              <img
                                src="/assets/img/avatar/avatar_12.png"
                                alt="Avatar"
                              />
                              <span>Johny E.</span>
                            </a>
                            <h3 className="cs-card_title">
                              <a onClick={() => toDetail(item)}>{item.name}</a>
                            </h3>
                            <div className="cs-card_price">
                              {t("home.price")}:{" "}
                              <b className="cs-primary_color">
                                {item.pay_token_number * 1} BPT
                              </b>
                            </div>
                            <hr />
                            <div className="cs-card_footer">
                              <span
                                className="cs-card_btn_1"
                                data-modal="#history_1"
                              >
                                {/* <i className="fas fa-redo fa-fw"></i>
                            View History */}
                              </span>
                              <a
                                onClick={() => toDetail(item)}
                                className="gradient-btn"
                              >
                                <span>{t("home.purchase")}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* .cs-slider_container */}
                {/* <div className="cs-slider_arrows cs-style1 cs-center cs-hidden_mobile">
                  <div className="cs-left_arrow cs-center cs-box_shadow">
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.0269 7.55957H0.817552"
                        stroke="currentColor"
                        strokeWidth="1.16474"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.92188 1.45508L0.817222 7.55973L6.92188 13.6644"
                        stroke="currentColor"
                        strokeWidth="1.16474"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="cs-right_arrow cs-center cs-box_shadow">
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.816895 7.55957H13.0262"
                        stroke="currentColor"
                        strokeWidth="1.16474"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.92188 1.45508L13.0265 7.55973L6.92188 13.6644"
                        stroke="currentColor"
                        strokeWidth="1.16474"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div> */}
                <div className="cs-pagination cs-style1 cs-hidden"></div>
              </div>
              {/* .cs-slider */}
            </div>
          </div>
        </div>
      </section>
      {/* End Hero */}

      <div className="cs-height_95 cs-height_lg_70"></div>

      {/* Start Category */}
      <section>
        <div className="container">
          <h2 className="cs-section_heading cs-style1">
            <img className="icon" src="/assets/img/home2.png" alt="" />
            &nbsp;{t("home.weekly_best_seller")}
          </h2>
          <div className="cs-height_45 cs-height_lg_45"></div>
          <div className="row">
            {categorys.map((item, idx) => (
              <div className="col-xl-3 col-lg-4 col-md-6" key={idx}>
                <div className="cs-card cs-style5 cs-white_bg cs-box_shadow category-box">
                  <div className="img-box">
                    <img src="/assets/img/avatar/avatar_37.png" alt="" />
                  </div>
                  <div className="info">
                    <div className="top">
                      <div className="name">{item.name}</div>
                      <div className="tip">{item.val}</div>
                    </div>
                    <div className="line"></div>
                    <div className="bottom">
                      <div className="tip">
                        {" "}
                        <i className="fas fa-list-ul fa-fw"></i> {item.item}{" "}
                        Items
                      </div>
                      <div className="tip">
                        <i className="far fa-eye fa-fw"></i>
                        {item.view} Views
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cs-height_30 cs-height_lg_30"></div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* End Category */}

      <div className="cs-height_70 cs-height_lg_40"></div>

      {/* Start New Items */}
      <section>
        <div className="container">
          <div className="cs-section_heading cs-style2">
            <div className="cs-section_left">
              <h2 className="cs-section_title">
                <img src="/assets/img/home3.png" alt="" />
                &nbsp;{t("home.new_artwork")}
              </h2>
            </div>
            <div className="cs-section_right">
              <a href="#/bigtech" className="cs-btn cs-style1">
                <span>{t("home.browse")}</span>
              </a>
            </div>
          </div>
          <div className="cs-isotop_filter cs-style1 cs-type1 cs-center">
            {/* <ul className="cs-mp0 cs-center">
            <li className="active"><a href="#" data-filter="*"><span>All BPT</span></a></li>
            <li><a href="#" data-filter=".fashion"><span>Fashion</span></a></li>
            <li><a href="#" data-filter=".music"><span>Music</span></a></li>
            <li><a href="#" data-filter=".video"><span>Video</span></a></li>
            <li><a href="#" data-filter=".games"><span>Games</span></a></li>
          </ul> */}
          </div>
          <div className="cs-height_45 cs-height_lg_45"></div>
          <div
            className="cs-isotop cs-style1 cs-isotop_col_5 cs-has_gutter_30"
            ref={list1Dom}
          >
            <div className="cs-grid_sizer"></div>
            <CardList
              length={10}
              type={process.env.REACT_APP_GUP}
              renderCall={() => renderCall(list1Dom.current)}
            />
          </div>
        </div>
      </section>
      {/* End New Items */}

      <div className="cs-height_95 cs-height_lg_70"></div>
      {/* Start New Items */}
      <section>
        <div className="container">
          <div className="cs-section_heading cs-style2">
            <div className="cs-section_left">
              <h2 className="cs-section_title">
                <img src="/assets/img/home4.png" alt="" />
                &nbsp;
                {t("home.new_patent_nft")}
              </h2>
            </div>
            <div className="cs-section_right">
              <a href="#/general" className="cs-btn cs-style1">
                <span>{t("home.browse")}</span>
              </a>
            </div>
          </div>
          <div className="cs-isotop_filter cs-style1 cs-type1 cs-center">
            {/* <ul className="cs-mp0 cs-center">
            <li className="active"><a href="#" data-filter="*"><span>All BPT</span></a></li>
            <li><a href="#" data-filter=".fashion"><span>Fashion</span></a></li>
            <li><a href="#" data-filter=".music"><span>Music</span></a></li>
            <li><a href="#" data-filter=".video"><span>Video</span></a></li>
            <li><a href="#" data-filter=".games"><span>Games</span></a></li>
          </ul> */}
          </div>
          <div className="cs-height_45 cs-height_lg_45"></div>
          <div
            className="cs-isotop cs-style1 cs-isotop_col_5 cs-has_gutter_30"
            ref={list2Dom}
          >
            <div className="cs-grid_sizer"></div>
            <CardList
              length={10}
              type={process.env.REACT_APP_GENERAL}
              renderCall={() => renderCall(list2Dom.current)}
            />
          </div>
        </div>
      </section>
      {/* End New Items */}

      {/* <div className="cs-height_95 cs-height_lg_70"></div> */}

      {/* Start Icon Boxes */}
      <Boxes />
      {/* <section>
        <div className="container">
          <h2 className="cs-section_heading cs-style1 text-center">
            {t("home.platform_advantages")}
          </h2>
          <div className="cs-height_45 cs-height_lg_45"></div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="cs-iconbox cs-style1 cs-white_bg">
                <div className="cs-iconbox_icon">
                  <img
                    style={{ width: "54px", height: "54px" }}
                    src="/assets/img/home/homeicon1.png"
                    alt=""
                  />
                </div>
                <div
                  className="cs-iconbox_subtitle"
                  style={{ minHeight: "6em" }}
                >
                  {t("home.reward_inventors")}
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs-iconbox cs-style1 cs-white_bg">
                <div className="cs-iconbox_icon">
                  <img
                    style={{ width: "54px", height: "54px" }}
                    src="/assets/img/home/homeicon2.png"
                    alt=""
                  />
                </div>
                <div
                  className="cs-iconbox_subtitle"
                  style={{ minHeight: "6em" }}
                >
                  {t("home.bpt_token")}
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs-iconbox cs-style1 cs-white_bg">
                <div className="cs-iconbox_icon">
                  <img
                    style={{ width: "54px", height: "54px" }}
                    src="/assets/img/home/homeicon3.png"
                    alt=""
                  />
                </div>
                <div
                  className="cs-iconbox_subtitle"
                  style={{ minHeight: "6em" }}
                >
                  {t("home.digital_assets")}
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs-iconbox cs-style1 cs-white_bg">
                <div className="cs-iconbox_icon">
                  <img
                    style={{ width: "54px", height: "54px" }}
                    src="/assets/img/home/homeicon4.png"
                    alt=""
                  />
                </div>
                <div
                  className="cs-iconbox_subtitle"
                  style={{ minHeight: "6em" }}
                >
                  {t("home.ai_drafting_tool")}{" "}
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>
          </div>
        </div>
      </section> */}
      {/* End Icon Boxes */}

      {/* <div className="cs-height_70 cs-height_lg_40"></div> */}

      {/* Start CTA */}
      {/* <section>
        <div className="container">
          <div className="cs-cta cs-style2 text-center cs-accent_bg">
            <h2 className="cs-cta_title cs-white_color_8">
              {t("home.connect_wallet")}
            </h2>
            <div className="cs-height_70 cs-height_lg_70"></div>
            <a
              href="#/connect-wallet"
              className="cs-btn cs-style1 cs-btn_lg cs-color2"
            >
              <span>{t("home.wallet_connection")}</span>
            </a>
          </div>
        </div>
      </section> */}
      {/* End CTA */}
      <div className="cs-height_50 cs-height_lg_70"></div>
      <div className="cs-footer1">
        <div className="container">
          <div className=" row2">
            <h3 className="GmarketSans weight400">
              {/* Best Patent NFT를 거래하려면
              <br></br>
              암호화폐 지갑을 연결하세요 */}
              <div
                dangerouslySetInnerHTML={{ __html: t("home.wallet_connect") }}
              ></div>
            </h3>
            <button
              className="footer-btn GmarketSans weight400"
              onClick={connectWallet}
            >
              {t("home.wallet_connection")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
