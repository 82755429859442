import { useState, useEffect } from "react";
import http from "@/http";
import { useTranslation } from "react-i18next";
import MineLayout from "@/components/mine-layout.jsx";
export default function UserAccountSettings() {
  const { t } = useTranslation();
  let [list, setList] = useState([]);
  let [activeItem, setActiveItem] = useState({});
  useEffect(() => {
    http.get("/system/idea/list", { pageSize: 999 }).then((res) => {
      setList(res.rows);
    });
  }, []);
  return (
    <>
      <MineLayout>
        <div className="cs-profile_right">
          <div className="cs-height_30 cs-height_lg_30"></div>
          <h2 className="cs-section_heading cs-style1">
            {t("myIdea.my_ideas")}
          </h2>
          <div className="cs-height_25 cs-height_lg_25"></div>
          <div className="row">
            <div className="cs-card cs-style4 cs-white_bg text-center">
              <table>
                <thead>
                  <tr>
                    <th>{t("myIdea.title")}</th>
                    <th>{t("myIdea.creation_date")}</th>
                    <th>{t("myIdea.body")}</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item) => (
                    <tr key={item.id}>
                      <td>{item.title}</td>
                      <td>{item.createTime.split(" ")[0]}</td>
                      <td>
                        <button
                          className="cs-btn cs-style1"
                          data-modal="#idea_pop"
                          onClick={() => setActiveItem(item)}
                        >
                          <span>{t("myIdea.view_original")}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                  {!list.length && (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        not data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </MineLayout>
      <div className="cs-modal_wrap" id="idea_pop">
        <div className="cs-modal_overlay"></div>
        <div className="cs-modal_container">
          <div className="cs-modal_container_in">
            <div className="cs-modal_close cs-center">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9649 2.54988C12.3554 2.15936 12.3554 1.52619 11.9649 1.13567C11.5744 0.745142 10.9412 0.745142 10.5507 1.13567L11.9649 2.54988ZM0.550706 11.1357C0.160181 11.5262 0.160181 12.1594 0.550706 12.5499C0.94123 12.9404 1.5744 12.9404 1.96492 12.5499L0.550706 11.1357ZM1.96492 1.13567C1.5744 0.745142 0.94123 0.745142 0.550706 1.13567C0.160181 1.52619 0.160181 2.15936 0.550706 2.54988L1.96492 1.13567ZM10.5507 12.5499C10.9412 12.9404 11.5744 12.9404 11.9649 12.5499C12.3554 12.1594 12.3554 11.5262 11.9649 11.1357L10.5507 12.5499ZM10.5507 1.13567L0.550706 11.1357L1.96492 12.5499L11.9649 2.54988L10.5507 1.13567ZM0.550706 2.54988L10.5507 12.5499L11.9649 11.1357L1.96492 1.13567L0.550706 2.54988Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="cs-bid_card">
              <div className="cs-bid_info">
                <ul>
                  <li>
                    <span>
                      {t("myIdea.creation_date")}:
                      {activeItem?.createTime?.split(" ")[0]}
                    </span>
                  </li>
                  <li style={{ justifyContent: "start" }}>
                    <span>{t("myIdea.title")} :</span>
                    <b style={{ marginLeft: "10px" }}>{activeItem.title}</b>
                  </li>
                </ul>
                <hr />
                <ul>
                  <li style={{ justifyContent: "start" }}>
                    <span>{t("myIdea.body")} :</span>
                    <b style={{ marginLeft: "10px" }}>{activeItem.content}</b>
                  </li>
                  <li style={{ wordBreak: "break-all" }}>
                    {activeItem.fileList?.map((item) => (
                      <a href={item} download="download" target="_blank">
                        {item}
                      </a>
                    ))}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
