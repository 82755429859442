import BInput from "@/components/b-input.jsx"
import BSelect from "@/components/b-select.jsx"
export default ({ step, formData, updateForm }) => {
  const checkFile = () => {
    $('<input type="file" accept=".jpg,.gif,.png,.hwp,.ppt,.pptx"/>')
      .change(function (event) {
        let form = new FormData();
        form.append("file", this.files[0]);
        http
          .post("/common/upload", form, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            updateForm(() => {
              formData.companyUrl = res.url
            })()
          });
      })
      .trigger("click");
  };
  return <>
    <h4 className="h-title">특허 데이터</h4>
    <div className="item">
      <div className="label">NFT 판매유형<b className="cs-form-item_must">*</b></div>
      <div className="d-flex tab-nav">
        <div className={"tab-item " + (formData.userBusType == 1 ? 'active' : '')} onClick={updateForm(() => formData.userBusType = 1)}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 21.5V5.16667H5.16667V0.5H16.8333V9.83333H21.5V21.5H12.1667V16.8333H9.83333V21.5H0.5ZM2.83333 19.1667H5.16667V16.8333H2.83333V19.1667ZM2.83333 14.5H5.16667V12.1667H2.83333V14.5ZM2.83333 9.83333H5.16667V7.5H2.83333V9.83333ZM7.5 14.5H9.83333V12.1667H7.5V14.5ZM7.5 9.83333H9.83333V7.5H7.5V9.83333ZM7.5 5.16667H9.83333V2.83333H7.5V5.16667ZM12.1667 14.5H14.5V12.1667H12.1667V14.5ZM12.1667 9.83333H14.5V7.5H12.1667V9.83333ZM12.1667 5.16667H14.5V2.83333H12.1667V5.16667ZM16.8333 19.1667H19.1667V16.8333H16.8333V19.1667ZM16.8333 14.5H19.1667V12.1667H16.8333V14.5Z" fill="currentColor" />
          </svg>

          &nbsp;
          후원
        </div>
        <div className={"tab-item " + (formData.userBusType == 0 ? 'active' : '')} onClick={updateForm(() => formData.userBusType = 0)}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99935 10.0013C8.71602 10.0013 7.6174 9.54436 6.70352 8.63047C5.78963 7.71658 5.33268 6.61797 5.33268 5.33464C5.33268 4.0513 5.78963 2.95269 6.70352 2.0388C7.6174 1.12491 8.71602 0.667969 9.99935 0.667969C11.2827 0.667969 12.3813 1.12491 13.2952 2.0388C14.2091 2.95269 14.666 4.0513 14.666 5.33464C14.666 6.61797 14.2091 7.71658 13.2952 8.63047C12.3813 9.54436 11.2827 10.0013 9.99935 10.0013ZM0.666016 19.3346V16.068C0.666016 15.4069 0.836155 14.7992 1.17643 14.2451C1.51671 13.6909 1.96879 13.268 2.53268 12.9763C3.73824 12.3735 4.96324 11.9214 6.20768 11.6201C7.45213 11.3187 8.71602 11.168 9.99935 11.168C11.2827 11.168 12.5466 11.3187 13.791 11.6201C15.0355 11.9214 16.2605 12.3735 17.466 12.9763C18.0299 13.268 18.482 13.6909 18.8223 14.2451C19.1625 14.7992 19.3327 15.4069 19.3327 16.068V19.3346H0.666016ZM2.99935 17.0013H16.9993V16.068C16.9993 15.8541 16.9459 15.6596 16.8389 15.4846C16.732 15.3096 16.591 15.1735 16.416 15.0763C15.366 14.5513 14.3063 14.1576 13.2368 13.8951C12.1674 13.6326 11.0882 13.5013 9.99935 13.5013C8.91046 13.5013 7.83129 13.6326 6.76185 13.8951C5.6924 14.1576 4.63268 14.5513 3.58268 15.0763C3.40768 15.1735 3.26671 15.3096 3.15977 15.4846C3.05282 15.6596 2.99935 15.8541 2.99935 16.068V17.0013ZM9.99935 7.66797C10.641 7.66797 11.1903 7.4395 11.6473 6.98255C12.1042 6.52561 12.3327 5.9763 12.3327 5.33464C12.3327 4.69297 12.1042 4.14366 11.6473 3.68672C11.1903 3.22977 10.641 3.0013 9.99935 3.0013C9.35768 3.0013 8.80838 3.22977 8.35143 3.68672C7.89449 4.14366 7.66602 4.69297 7.66602 5.33464C7.66602 5.9763 7.89449 6.52561 8.35143 6.98255C8.80838 7.4395 9.35768 7.66797 9.99935 7.66797Z" fill="currentColor" />
          </svg>

          &nbsp;
          특허
        </div>
      </div>
    </div>
    {formData.userBusType == 1 && <>
      <div className="item">
        <div className="label">특허명<b className="cs-form-item_must">*</b></div>
        <BInput value={formData.companyName} onChange={updateForm((ev) => formData.companyName = ev.target.value)} close />
      </div>
      <div className="item">
        <div className="label">특허 등록증 업로드<b className="cs-form-item_must">*</b></div>
        <div className="d-flex gap-3 upload-img">
          {formData.companyUrl && <div className="img">
            <img src={formData.companyUrl} alt="" />
          </div>}

          <div className="option">
            <div>
              <div className="title">지원되는 파일 형식</div>
              <div className="tip">JPG, PNG, GIF, SVG, MP4 다양한 유형의 파일에 대한 치수 요구사항은 다음과 같습니다.
                아바타(320 x 320px), 가로(1080 x 566px), 세로(1080 x 1350px), 사각형(1080 x 1080px)</div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <button className="upload-btn" onClick={checkFile}>
                <span>
                  Upload
                </span>
              </button>
              <div className="pointer d-flex align-items-center" onClick={updateForm(() => formData.companyUrl = '')}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.66797 14C4.3013 14 3.98741 13.8694 3.7263 13.6083C3.46519 13.3472 3.33464 13.0333 3.33464 12.6667V4H2.66797V2.66667H6.0013V2H10.0013V2.66667H13.3346V4H12.668V12.6667C12.668 13.0333 12.5374 13.3472 12.2763 13.6083C12.0152 13.8694 11.7013 14 11.3346 14H4.66797ZM11.3346 4H4.66797V12.6667H11.3346V4ZM6.0013 11.3333H7.33464V5.33333H6.0013V11.3333ZM8.66797 11.3333H10.0013V5.33333H8.66797V11.3333Z" fill="#494F6A" />
                </svg>
                &nbsp;
                Delete
              </div>
            </div>
          </div>
        </div>
      </div>
    </>}
    <div className="item d-flex gap-3">
      <div className="flex-fill">
        <div className="label">성(법인)<b className="cs-form-item_must">*</b></div>
        <BInput value={formData.surname} onChange={updateForm((ev) => formData.surname = ev.target.value)} />
      </div>
      <div className="flex-fill">
        <div className="label">이름(법인)<b className="cs-form-item_must">*</b></div>
        <BInput value={formData.name} onChange={updateForm((ev) => formData.name = ev.target.value)} />
      </div>
    </div>
    <div className="item">
      <div className="label">전화번호<b className="cs-form-item_must">*</b></div>
      <BInput value={formData.phonenumber} onChange={updateForm((ev) => formData.phonenumber = ev.target.value)} />
    </div>
    <div className="item">
      <div className="label">등록번호<b className="cs-form-item_must">*</b></div>
      <BInput after={
        <button className="code">
          인증코드 전송
        </button>
      } />
    </div>
    {formData.userBusType == 0 &&
      <div className="item d-flex gap-3">
        <div className="flex-1">
          <div className="label">구분<b className="cs-form-item_must">*</b></div>
          <BSelect value={formData.distinction} onChange={updateForm((val) => formData.distinction = val)} />
        </div>
        <div className="flex-1">
          <div className="label">국가<b className="cs-form-item_must">*</b></div>
          <BInput disabled={!formData.distinction} />
        </div>
      </div>
    }
    <div className="item">
      <div className="label">신청일<b className="cs-form-item_must">*</b></div>
      <BInput type="date" value={formData.appTime} onChange={updateForm((ev) => formData.appTime = ev.target.value)} />
    </div>
    <div className="d-flex gap-2">
      <button className="cs-btn cs-style1 cs-btn_lg flex-1 gray" onClick={() => step[1](3)}><span>이전</span></button>
      <button className="cs-btn cs-style1 cs-btn_lg hollow flex-1"><span className="bg-text">저장</span></button>
    </div>
    &nbsp;
    <button className="cs-btn cs-style1 cs-btn_lg w-100"><span>제출</span></button>
  </>
}