import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import http from "../http";
import { getTokenURI } from "../utils/ethNft.js";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import IdeaKo from "@/agreement/idea-ko.jsx";
import IdeaEn from "@/agreement/idea-en.jsx";
import { connect } from "react-redux";
import "@/assets/sass/idea.scss";
function Explore1(props) {
  const { t } = useTranslation();
  let [title, setTitle] = useState("");
  let [content, setContent] = useState("");
  let [fileList, setFileList] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const checkFile = () => {
    $('<input type="file" accept=".jpg,.gif,.png,.hwp,.ppt,.pptx"/>')
      .change(function (event) {
        let form = new FormData();
        form.append("file", this.files[0]);
        http
          .post("/common/upload", form, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            setFileList([...fileList, res]);
          });
      })
      .trigger("click");
  };
  let delImg = (res) => {
    setFileList(fileList.filter((item) => item != res));
  };
  let submit = () => {
    if (!title) {
      messageApi.open({
        type: "error",
        content: t("idea.idea_enter_title"),
      });
      return;
    }
    if (!content) {
      messageApi.open({
        type: "error",
        content: t("idea.idea_enter_content"),
      });
      return;
    }
    http
      .post("/system/idea", {
        title,
        content,
        fileList: fileList.map((item) => item.url),
      })
      .then((res) => {
        setTitle("");
        setContent("");
        setFileList([]);
      });
  };
  return (
    <>
      {contextHolder}
      {/* End Header Section */}
      <div className="cs-height_90 cs-height_lg_80"></div>
      {/* Start Page Head */}
      <section className="cs-page_head cs-bg" data-src="/assets/img/idea.jpg">
        <div className="container" style={{ color: "#fff" }}>
          <div className="text-center">
            <h1 className="cs-page_title" style={{ color: "#fff" }}>
              {t("idea.idea_challenge")}
            </h1>
            <img src="/assets/img/line.png" alt="Avatar" />
            <ol className="breadcrumb">
              <p
                dangerouslySetInnerHTML={{ __html: t("idea.description") }}
              ></p>
            </ol>
          </div>
        </div>
      </section>
      <div className="cs-height_50 cs-height_lg_40"></div>
      <div className="idea-container ">
        <div className="content">
          <div className="left">
            <div className={"idea-content " + ("language_" + props.language)}>
              <div className="relative z1">
                {props.language == "ko" && <IdeaKo />}
                {props.language == "en" && <IdeaEn />}
              </div>
            </div>
          </div>
          <div className="right">
            <div className="cs-section_heading cs-style4">
              <h2 className="cs-section_title">{t("idea.idea_info")}</h2>
            </div>
            <div className="cs-height_45 cs-height_lg_45"></div>
            <div className="cs-contact_form">
              <div className="row">
                <div className="col-lg-12">
                  <div className="title">{t("idea.title")}</div>
                  <div className="cs-form_field_wrap">
                    <input
                      type="text"
                      className="cs-form_field"
                      placeholder={t("idea.input_title")}
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                    />
                  </div>
                  <div className="cs-height_20 cs-height_lg_20"></div>
                </div>
                <div className="col-lg-12">
                  <div className="title">{t("idea.body")}</div>
                  <div className="cs-form_field_wrap">
                    <textarea
                      cols="30"
                      rows="5"
                      style={{ height: "200px" }}
                      placeholder={t("idea.content")}
                      className="cs-form_field"
                      value={content}
                      onChange={(event) => setContent(event.target.value)}
                    ></textarea>
                  </div>
                  <div className="line"></div>
                </div>
                <div className="col-lg-12">
                  <div className="file-list-title">
                    <div className="title">{t("idea.file")}</div>
                    {fileList.length < 3 && (
                      <div className="cs-edit_profile_btns">
                        <a onClick={checkFile} className="cs-upload_btn">
                          {t("idea.select_file")}
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="cs-edit_profile">
                    <div className="cs-edit_profile_right">
                      {fileList.map((item) => (
                        <div className="cs-edit_profile_btns">
                          <a className="cs-upload_btn">{item.newFileName}</a>
                          <span
                            className="cs-delete_btn"
                            onClick={() => delImg(item)}
                          >
                            <i className="far fa-trash-alt"></i> Delete
                          </span>
                        </div>
                      ))}

                      <p>{t("idea.attach_file")}</p>
                      <ul className="tip">
                        <li>{t("idea.file_format")}</li>
                        <li>{t("idea.file_limit")}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="cs-height_20 cs-height_lg_20"></div>
                </div>
                <div className="col-lg-12">
                  <button
                    className="cs-btn cs-style1 cs-btn_lg"
                    onClick={submit}
                  >
                    <span>{t("idea.send")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs-height_90 cs-height_lg_80"></div>
    </>
  );
}

export default connect((state) => {
  return {
    language: state.language,
  };
})(Explore1);
