import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import http from "../http";
import { getTokenURI } from "../utils/ethNft.js";
import axios from "axios";
import { useTranslation } from "react-i18next";
export default function CardList({ length, itemClass, type, renderCall }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let [data, setData] = useState([]);
  useEffect(() => {
    http
      .getnft(`/bpt-nft/nft_list?address=${type}&take=10`)
      .then(async (_result) => {
        try {
          // 使用 Promise.all 来等待所有异步操作完成
          const data = await Promise.all(
            window._.map(_result.list, async (_item) => {
              // 获取 token URI
              // const _nft = await getTokenURI(
              //   _item.contract_address,
              //   _item.token_id
              // );
              // // 获取 JSON 数据
              // const response = await axios.get(_nft.tokenURI);
              // if (!response.data) {
              //   throw new Error(`Failed to fetch ${_nft}`);
              // }
              const _nft = await http
                .getnft(
                  `/bpt-nft/nft_info?token_id=${_item.token_id}&address=${_item.contract_address}`
                )
                .then((res) => {
                  return res.info.nfi_info;
                });
              return {
                ..._item,
                ..._nft,
              };
            })
          );

          // 现在 data 是解析后的结果数组
          setData(data.splice(0, length));
        } catch (error) {
          console.error("Error fetching NFTs:", error);
        }
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    if (data.length) {
      renderCall && renderCall();
    }
  }, [data]);
  let toDetail = (item) => {
    localStorage.setItem("detail_" + item.id, JSON.stringify(item));
    navigate("/explore-details?id=" + item.id);
  };
  return (
    <>
      {data.map((item) => (
        <div
          className={itemClass ? itemClass : "cs-isotop_item fashion"}
          key={item.id}
        >
          <div className="cs-card cs-style4 cs-white_bg card-1">
            <span className="cs-card_like cs-primary_color">
              <i className="fas fa-heart fa-fw"></i>
              2.1K
            </span>
            <a
              onClick={() => toDetail(item)}
              className="cs-card_thumb cs-zoom_effect"
            >
              <img
                src={item.image}
                alt="Image"
                height={283}
                className="cs-zoom_item"
              />
            </a>
            <div className="cs-card_info">
              <a href="#" className="cs-avatar">
                <img src="/assets/img/avatar/avatar_12.png" alt="Avatar" />
                <span>Johny E.</span>
              </a>
              <h3 className="cs-card_title">
                <a onClick={() => toDetail(item)}>{item.name}</a>
              </h3>
              <div className="cs-card_price">
                {t("components.price")}:{" "}
                <b className="cs-primary_color">{item.pay_token_number} BPT</b>
              </div>
              <hr />
              <div className="cs-card_footer">
                <span className="cs-card_btn_1" data-modal="#history_1">
                  {/* <i className="fas fa-redo fa-fw"></i>
                      View History */}
                </span>
                <a
                  onClick={() => toDetail(item)}
                  className="cs-card_btn_2"
                  data-params={JSON.stringify(item)}
                >
                  <span>{t("components.purchase")}</span>
                </a>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
      ))}
    </>
  );
}
