export default () => (
  <>
    <div className="text-center mb-4">
      <h3 className="idea-title">Idea Challenge</h3>
      <br />
      <p className="idea-tip">Reuse and Recycling of Electric Vehicle Batteries</p>
    </div>
    <table className="table">
      <tr>
        <td className="label">Launch
          event</td><td > <b style={{ fontSize: "15.6px", letterSpacing: "-1px" }}>We are looking for innovative and creative ideas on reuse and recycling of used electric vehicle batteries!</b></td>
      </tr>
      <tr>
        <td className="label">Hosted /
          Sponsored</td><td>by PattenFT Co., Ltd. / BPT Foundation</td>
      </tr>
      <tr>
        <td className="label">Eligibility</td><td>Platform users</td>
      </tr>
      <tr>
        <td className="label">Schedule</td>
        <td>
          <p style={{ fontSize: "12px", color: "#7E85A1" }}>Submission Period</p>
          <p>September 1, 2024 ~ November 30, 2024 (3 months)</p>
          <p style={{ fontSize: "12px", color: "#7E85A1" }}>Submission Deadline</p>
          <p>September 1, 2024 ~ September 30, 2024 (1 month)</p>
          <p style={{ fontSize: "12px", color: "#7E85A1" }}>Mission Execution</p>
          <p>Bi-weekly (October 15, November 1, November 15, November 30)</p>
          <p style={{ fontSize: "12px", color: "#7E85A1" }}>Final Evaluation and Results Announcement</p>
          <p>December 15, 2024</p>
        </td>
      </tr>
      <tr>
        <td className="label">Contest
          Topic</td><td>
          <p>
            Ideas for reusing waste batteries
          </p>
          <ul>
            <li>Development of new products using waste batteries</li>
            <li>Improve performance by applying waste batteries to existing products</li>
          </ul>
          <p>
            Waste battery recycling ideas
          </p>
          <ul>
            <li>Development of efficient technology for recycling waste batteries</li>
            <li>How to solve problems that arise during the recycling process</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td className="label">
          How to
          apply
        </td>
        <td>
          <p>Sign up as a member on the BPT platform</p>
          <p>Fill out the basic idea information, attach the file, and submit</p>
        </td>
      </tr>
      <tr>
        <td className="label">Selection
          method</td>
        <td><p>Written evaluation by patent experts</p><p className="tip">Selection method</p>
          <table>
            <thead>
              <tr>
                <th>Indicators</th>
                <th>Score</th>
                <th>Desctiption</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Creativity</td>
                <td>30</td>
                <td>Is it an original and innovative idea?</td>
              </tr>
              <tr>
                <td>Feasibility</td>
                <td>30</td>
                <td>Is it technically and economically feasible?</td>
              </tr>
              <tr>
                <td>Sustainability</td>
                <td>30</td>
                <td>Does it have a positive impact on the environment?</td>
              </tr>
              <tr>
                <td>Social influence</td>
                <td>10</td>
                <td>Is there a significant positive effect on society?</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td className="label">Awards
          details</td>
        <td>
          <p className="tip">Selection method</p>
          <table>
            <thead>
              <tr>
                <th>reward</th>
                <th>Award scale</th>
                <th>Award benefits</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Best grand prize</td>
                <td>1person</td>
                <td>1,000BPT payment, AI patent
                  specification usage rights</td>
              </tr>
              <tr>
                <td>Grand Prize</td>
                <td>1person</td>
                <td>800BPT payment</td>
              </tr>
              <tr>
                <td>Excellence Award</td>
                <td>2person</td>
                <td>500BPT payment</td>
              </tr>
              <tr>
                <td>Encouragement Award</td>
                <td>3person</td>
                <td>300BPT payment</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td className="label">Contact</td>
        <td>patentnft@patent-nft.com</td>
      </tr>
      <tr>
        <td className="label">Other
          Information</td>
        <td>
          <ul>
            <li>If there is no suitable idea, it may not be selected.</li>
            <li>Ownership of submitted ideas belongs to the submitter. However, if you submit an idea as part of a company, please be careful to ensure that there are no ownership issues between the company and the submitter.</li>
            <li>The winning work may be put to practical use at the discretion of the organizer, and additional cooperation may be required in this process.</li>
            <li>Participants agree to all terms and conditions of this contest upon application.</li>
          </ul>
        </td>
      </tr>
    </table>
  </>
);
