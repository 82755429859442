import { useState } from "react";
import http from "../http";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Eye from "@/components/eye.jsx"
import Close from "@/components/close.jsx"
import "@/assets/sass/login.scss"
export default function Register() {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  let [name, setName] = useState("");
  let [phone, setPhone] = useState("");
  let [birthday, setBirthday] = useState("");
  let [walletAddress, setWalletAddress] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [code, setCode] = useState("");
  let [lastTime, setLastTime] = useState(0);
  let [uuid, setUuid] = useState("");
  let [nickName, setNickName] = useState("");
  let [userBusType, setUserBusType] = useState(0);
  let [showEye, setShowEye] = useState(false)
  let [showEye1, setShowEye1] = useState(false)
  let [options, setOptions] = useState({
    option1: false,
    option2: false,
    option3: false
  })
  let setOption = (key) => (val) => {
    options[key] = val,
      setOptions({ ...options })
  }
  let isCheckAll = () => options.option1 && options.option2 && options.option3
  let checkAll = () => {
    let check = !isCheckAll()
    for (let i in options) {
      options[i] = check
    }
    setOptions({ ...options })
  }
  let register = (event) => {
    event && event.preventDefault();
    let emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailReg.test(email)) {
      messageApi.open({
        type: "error",
        content: t("register.input_email"),
      });
      return;
    }
    if (!code) {
      messageApi.open({
        type: "error",
        content: t("register.input_auth_code"),
      });
      return;
    }
    let passwordReg = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
    if (!passwordReg.test(password)) {
      messageApi.open({
        type: "error",
        content: t("register.input_password"),
      });
      return;
    }
    if (password != confirmPassword) {
      messageApi.open({
        type: "error",
        content: t("register.password_mismatch"),
      });
      return;
    }
    if (!options.option1 || !options.option2) {
      messageApi.open({
        type: "error",
        content: t('register.agree_to_terms'),
      });
      return
    }
    if (!nickName) {
      messageApi.open({
        type: "error",
        content: t('register.enter_nickname'),
      });
      return;
    }
    // if (phone.length < 6) {
    //   messageApi.open({
    //     type: "error",
    //     content: t("register.input_phone"),
    //   });
    //   return;
    // }
    // if (!birthday) {
    //   messageApi.open({
    //     type: "error",
    //     content: t("register.input_birthday"),
    //   });
    //   return;
    // }

    http
      .post("/register", {
        name,
        phone,
        birthday,
        walletAddress,
        email,
        password,
        confirmPassword,
        uuid,
        code,
        nickName,
        userBusType
      })
      .then((res) => {
        navigate("/login");
      })
      .catch(() => { });
  };
  let sendMsgTime;
  let sendMsg = () => {
    // if (lastTime != 0) return;

    http
      .post("/getRegisterCode", {
        email,
      })
      .then((res) => {
        setUuid(res.uuid);
        lastTime = 60;
        setLastTime(lastTime);
        sendMsgTime = setInterval(() => {
          if (lastTime <= 0) {
            clearInterval(sendMsgTime);
            setLastTime(0);
            return;
          }
          lastTime--;
          setLastTime(lastTime);
        }, 1000);
      })
      .catch((err) => { });
  };
  return (
    <div className="register">
      {contextHolder}
      <div className="cs-height_90 cs-height_lg_80"></div>
      <div className="box">
        <div className="left">
        </div>
        <div className="right">
          <form className="cs-form_card">
            <h2 className="cs-form_title">
              {t('register.sign')}
            </h2>
            <p className="cs-form_tip"> {t('register.enter_information')}</p>
            <div className="cs-form_card_in">
              <div className="cs-form-item">
                <label className="cs-form_label">
                  {t('register.user_type')}
                </label>
                <div className="d-flex tab-nav">
                  <div className={"tab-item " + (userBusType == 0 ? 'active' : '')} onClick={() => setUserBusType(0)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="#96A0CB" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.0013 9.99935C8.71797 9.99935 7.61936 9.54241 6.70547 8.62852C5.79158 7.71463 5.33464 6.61602 5.33464 5.33268C5.33464 4.04935 5.79158 2.95074 6.70547 2.03685C7.61936 1.12296 8.71797 0.666016 10.0013 0.666016C11.2846 0.666016 12.3832 1.12296 13.2971 2.03685C14.211 2.95074 14.668 4.04935 14.668 5.33268C14.668 6.61602 14.211 7.71463 13.2971 8.62852C12.3832 9.54241 11.2846 9.99935 10.0013 9.99935ZM0.667969 19.3327V16.066C0.667969 15.4049 0.838108 14.7973 1.17839 14.2431C1.51866 13.6889 1.97075 13.266 2.53464 12.9743C3.74019 12.3716 4.96519 11.9195 6.20964 11.6181C7.45408 11.3167 8.71797 11.166 10.0013 11.166C11.2846 11.166 12.5485 11.3167 13.793 11.6181C15.0374 11.9195 16.2624 12.3716 17.468 12.9743C18.0319 13.266 18.4839 13.6889 18.8242 14.2431C19.1645 14.7973 19.3346 15.4049 19.3346 16.066V19.3327H0.667969ZM3.0013 16.9993H17.0013V16.066C17.0013 15.8521 16.9478 15.6577 16.8409 15.4827C16.7339 15.3077 16.593 15.1716 16.418 15.0744C15.368 14.5493 14.3082 14.1556 13.2388 13.8931C12.1694 13.6306 11.0902 13.4993 10.0013 13.4993C8.91241 13.4993 7.83325 13.6306 6.7638 13.8931C5.69436 14.1556 4.63464 14.5493 3.58464 15.0744C3.40964 15.1716 3.26866 15.3077 3.16172 15.4827C3.05477 15.6577 3.0013 15.8521 3.0013 16.066V16.9993ZM10.0013 7.66602C10.643 7.66602 11.1923 7.43754 11.6492 6.9806C12.1062 6.52365 12.3346 5.97435 12.3346 5.33268C12.3346 4.69102 12.1062 4.14171 11.6492 3.68477C11.1923 3.22782 10.643 2.99935 10.0013 2.99935C9.35964 2.99935 8.81033 3.22782 8.35339 3.68477C7.89644 4.14171 7.66797 4.69102 7.66797 5.33268C7.66797 5.97435 7.89644 6.52365 8.35339 6.9806C8.81033 7.43754 9.35964 7.66602 10.0013 7.66602Z" />
                    </svg>
                    &nbsp;
                    {t('register.individual')}
                  </div>
                  <div className={"tab-item " + (userBusType == 1 ? 'active' : '')} onClick={() => setUserBusType(1)}>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.5 21.5V5.16667H5.16667V0.5H16.8333V9.83333H21.5V21.5H12.1667V16.8333H9.83333V21.5H0.5ZM2.83333 19.1667H5.16667V16.8333H2.83333V19.1667ZM2.83333 14.5H5.16667V12.1667H2.83333V14.5ZM2.83333 9.83333H5.16667V7.5H2.83333V9.83333ZM7.5 14.5H9.83333V12.1667H7.5V14.5ZM7.5 9.83333H9.83333V7.5H7.5V9.83333ZM7.5 5.16667H9.83333V2.83333H7.5V5.16667ZM12.1667 14.5H14.5V12.1667H12.1667V14.5ZM12.1667 9.83333H14.5V7.5H12.1667V9.83333ZM12.1667 5.16667H14.5V2.83333H12.1667V5.16667ZM16.8333 19.1667H19.1667V16.8333H16.8333V19.1667ZM16.8333 14.5H19.1667V12.1667H16.8333V14.5Z" />
                    </svg>
                    &nbsp;
                    {t('register.business')}
                  </div>
                </div>
              </div>
              <div className="cs-height_15 cs-height_lg_15"></div>
              <div className="cs-form-item">
                <label className="cs-form_label">
                  {t('register.email')}<b className="cs-form-item_must">*</b>
                </label>
                <div className="cs-form_field_wrap">
                  <div className="cs-form_field cs-form-item_field">
                    <input
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      type="email"
                      placeholder={t("register.input_email_id")}
                    />
                    {email && <Close onClick={() => setEmail('')} />}
                  </div>
                </div>
              </div>
              <div className="cs-height_15 cs-height_lg_15"></div>
              <div className="cs-form-item">
                <label className="cs-form_label">
                  {t("register.auth_code")}
                  <b className="cs-form-item_must">*</b>
                </label>
                <div className="cs-form_field_wrap">
                  <div className="cs-form_field cs-form-item_field" style={{ paddingTop: "5px", paddingBottom: '5px', height: "52px" }}>
                    <input
                      value={code}
                      onChange={(event) => setCode(event.target.value)}
                      type="text"
                      style={{ width: "100px" }}
                      placeholder={t("register.input_auth_code")}
                    />
                    {!code && <div onClick={sendMsg} className="code pointer">
                      {t("register.send_auth_code")}
                    </div>}
                  </div>
                </div>
              </div>
              <div className="cs-height_15 cs-height_lg_15"></div>
              <div className="cs-form-item">
                <label className="cs-form_label">
                  {t("register.password")} <b className="cs-form-item_must">*</b>
                </label>
                <div className="cs-form_field_wrap">
                  <div className="cs-form_field cs-form-item_field">
                    <input
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      type={showEye ? "text" : "password"}
                      placeholder={t("register.password_requirements")}
                    />
                    <Eye show={showEye} onClick={() => setShowEye(!showEye)} />
                  </div>
                </div>
              </div>
              <div className="cs-height_15 cs-height_lg_15"></div>
              <div className="cs-form-item">
                <label className="cs-form_label">
                  {t("register.confirm_password")}
                  <b className="cs-form-item_must">*</b>
                </label>
                <div className="cs-form_field_wrap">
                  <div className="cs-form_field cs-form-item_field">
                    <input
                      value={confirmPassword}
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                      type={showEye1 ? "text" : "password"}
                      placeholder={t("register.password_requirements")}
                    />
                    <Eye show={showEye1} onClick={() => setShowEye1(!showEye1)} />
                  </div>
                </div>
              </div>
              <div className="cs-height_15 cs-height_lg_15"></div>
              <div className="cs-form-item">
                <label className="cs-form_label">
                  {t("register.nickname")}
                  <b className="cs-form-item_must">*</b>
                </label>
                <div className="cs-form_field_wrap">
                  <div className="cs-form_field cs-form-item_field">
                    <input
                      value={nickName}
                      onChange={(event) =>
                        setNickName(event.target.value)
                      }
                      type="text"
                      placeholder={t("register.enter_nickname_again")}
                    />
                  </div>
                </div>
              </div>
              <div className="cs-height_15 cs-height_lg_15"></div>
              <div className="cs-form-item">
                <label className="cs-form_label">
                  {t("register.wallet_address")}
                </label>
                <div className="cs-form_field_wrap">
                  <div className="cs-form_field cs-form-item_field">
                    <input
                      value={walletAddress}
                      onChange={(event) =>
                        setWalletAddress(event.target.value)
                      }
                      type="text"
                      placeholder={t("register.input_wallet_address")}
                    />
                  </div>
                </div>
              </div>

              <div className="cs-height_30 cs-height_lg_30"></div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="notify"
                  checked={isCheckAll()}
                  onChange={checkAll}
                />
                <label className="form-check-label" htmlFor="notify">
                  {t('register.agree_all')}
                </label>
              </div>
              <div className="cs-height_15 cs-height_lg_15"></div>
              <div className="line"></div>
              <div className="cs-height_15 cs-height_lg_15"></div>
              <div className="form-check pointer d-flex" onClick={() => navigate('/terms-condition')}>
                <input className="form-check-input" onClick={(e) => e.stopPropagation()} type="checkbox" checked={options.option1} onChange={() => setOption('option1')(!options.option1)} id="terms" />
                &nbsp;
                <label className="form-check-label" htmlFor="terms">
                  <span style={{ color: '#3772FF' }}>[{t('register.required')}]</span> BEST PATENT {t('register.agree_to_terms_of_service')}
                </label>
                <svg className="ms-auto float-end" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.168 9.97917L6.29297 6.10417L7.83464 4.5625L13.2513 9.97917L7.83464 15.3958L6.29297 13.8542L10.168 9.97917Z" fill="#494F6A" />
                </svg>
              </div>
              <div className="cs-height_10 cs-height_lg_10"></div>
              <div className="form-check pointer d-flex" onClick={() => navigate('/privacy-policy')}>

                <input className="form-check-input" onClick={(e) => e.stopPropagation()} type="checkbox" checked={options.option2} onChange={() => setOption('option2')(!options.option2)} id="terms1" />
                &nbsp;
                <label className="form-check-label flex-1" htmlFor="terms1">
                  <span style={{ color: '#3772FF' }}>[{t('register.required')}]</span> {t('register.agree_to_privacy_policy')}
                </label>
                <svg className="ms-auto float-end" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.168 9.97917L6.29297 6.10417L7.83464 4.5625L13.2513 9.97917L7.83464 15.3958L6.29297 13.8542L10.168 9.97917Z" fill="#494F6A" />
                </svg>
              </div>
              <div className="cs-height_10 cs-height_lg_10"></div>
              <div className="form-check d-flex">
                <input className="form-check-input" type="checkbox" checked={options.option3} onChange={() => setOption('option3')(!options.option3)} id="terms2" />
                &nbsp;
                <label className="form-check-label" htmlFor="terms2">
                  <span style={{ color: '#96A0CB' }}>[{t('register.optional')}]</span> {t('register.agree_to_promotional_info')}
                </label>

              </div>
              <div className="cs-height_10 cs-height_lg_10"></div>
              <button
                className="cs-btn cs-style1 cs-btn_lg w-100"
                onClick={register}
              >
                <span>{t('register.confirm')}</span>
              </button>
              <div className="cs-height_30 cs-height_lg_30"></div>
              <div className="cs-form_footer text-center">
                {t('register.already_have_account')}? &nbsp; <a href="#/login" className="text-primary">{t('register.login')}</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
