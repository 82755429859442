import { useTranslation } from "react-i18next";
import MineLayout from "@/components/mine-layout.jsx";
import http from "../http";
import { message } from "antd";
import { useEffect, useState } from "react";
import PagingBtn from "@/components/paging-btn.jsx"
import "@/assets/sass/nft-application.scss"
import { useNavigate } from "react-router-dom";
export default () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  let [pas, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  let setPas = (key) => (event) => {
    pas[key] = event.target.value;
    setPassword({ ...pas });
  };
  return (<>
    <MineLayout>
      <div className="cs-profile_right nft-application ">
        <div className="cs-height_30 cs-height_lg_30"></div>
        <h2 className="cs-section_heading cs-style1 d-flex justify-content-between">
          <div>
            {t('mine.my_nft_application')}
          </div>
          {/* <button className="cs-btn cs-style1 fs-6" onClick={() => navigate('/nft-application-form')}><span>{t('mine.nft_application')}</span></button> */}
        </h2>
        <div className="cs-height_20 cs-height_lg_20"></div>
        <div className="cs-tabs cs-fade_tabs">
          <ul className="cs-tab_links cs-style1  cs-primary_color cs-mp0">
            <li className="active">
              <a href="#tab1"><span className="txt">{t('mine.application_approved')}</span></a>
            </li>
            <li>
              <a href="#tab2"><span className="txt">{t('mine.application_denied')}</span></a>
            </li>
          </ul>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <div className="cs-tab_content">
            <div className="cs-tab active" id="tab1">
              {Array(0).fill('').map((item, idx) => <div className="order d-flex" key={idx}>
                <div className="time">
                  <div className="year">2024</div>
                  <div className="m">
                    <b>7</b>
                    월
                    <b>22</b>
                    일
                  </div>
                </div>
                <img className="img" src="http://nft.patentnftx.com/bigtech/image/US10741183_1007.png" />
                <div className="data">
                  <div className="d-flex top">
                    <div className="dage">후원</div>
                    <div className="title">가나다라마바사</div>
                    <div className="flex-fill">
                      <span className="bg-text">판매 중</span>
                    </div>
                    <div className="price">
                      <span className="bg-text">24.112</span>
                      BTP
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="info">국회에 제출된 법률안 기타의 의안은 회기중에 의결되지 못한 이유로 폐기되지 아니한다. 다만, 국회의원의 임기가 만료된 때에는 그러하지 아니하다. 국정감사 및 조사에 관한 절차 기타 필요한 사항은 법률로 정한다. 국회의원의 선거구와 비례대표제 기타 선거에 관한 사항은 법률로 정한다. 이 헌법시행 당시에 이 헌법에 의하여 새로 설치될 기관의 권한에 속하는 직무를 행하고 있는 기관은 이 헌법에 의하여 새로운 기관이 설치될 때까지 존속하며 그 직무를 행한다.</div>
                </div>
              </div>)}

              <PagingBtn />
            </div>
            <div className="cs-tab" id="tab2">
              {Array(0).fill('').map((item, idx) => <div className="d-flex order2" key={idx}>
                <div className="time">
                  <div className="year">2024</div>
                  <div className="m">
                    <b>7</b>
                    월
                    <b>22</b>
                    일
                  </div>
                </div>
                <img className="img" src="http://nft.patentnftx.com/bigtech/image/US10741183_1007.png" />
                <div className="data">
                  <div className="d-flex top">
                    <div className="dage">후원</div>
                    <div className="title">가나다라마바사</div>
                    <div className="flex-fill">
                      <span className="bg-text">판매 중</span>
                    </div>
                    <div className="price">
                      <span className="bg-text">24.112</span>
                      BTP
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="info">국회에 제출된 법률안 기타의 의안은 회기중에 의결되지 못한 이유로 폐기되지 아니한다. 다만, 국회의원의 임기가 만료된 때에는 그러하지 아니하다. 국정감사 및 조사에 관한 절차 기타 필요한 사항은 법률로 정한다. 국회의원의 선거구와 비례대표제 기타 선거에 관한 사항은 법률로 정한다. 이 헌법시행 당시에 이 헌법에 의하여 새로 설치될 기관의 권한에 속하는 직무를 행하고 있는 기관은 이 헌법에 의하여 새로운 기관이 설치될 때까지 존속하며 그 직무를 행한다.</div>
                  <div className="d-flex justify-content-between align-items-center option">
                    <div className="red">실패 원인: 1. 이미 동일한 이미지 파일의 NFT 작품이 존재합니다.</div>
                    <div className="button">수정</div>
                  </div>
                </div>
              </div>)}
              <PagingBtn />
            </div>
          </div>
        </div>
      </div>
    </MineLayout>
  </>)
}