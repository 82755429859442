import { useTranslation } from "react-i18next";
import PrivacyPolicyKo from "@/agreement/privacy-policy-ko.jsx";
import PrivacyPolicyEn from "@/agreement/privacy-policy-en.jsx";
import { connect } from "react-redux";
function PrivacyPolicy(props) {
  const { t } = useTranslation();
  return (
    <>
      {/* End Header Section */}

      <div className="cs-height_90 cs-height_lg_80"></div>
      {/* Start Page Head */}
      <section
        className="cs-page_head cs-bg"
        data-src="/assets/img/page_head_bg.svg"
      >
        <div className="container">
          <div className="text-center">
            <h1 className="cs-page_title">
              {t("privacyPolicy.privacy_policy")}
            </h1>
            {/* <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#/index">{t("privacyPolicy.home")}</a>
              </li>
              <li className="breadcrumb-item active">
                {t("privacyPolicy.privacy_policy")}
              </li>
            </ol> */}
          </div>
        </div>
      </section>
      {/* End Page Head */}

      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="cs-single_post">
              {props.language == "ko" && <PrivacyPolicyKo />}
              {props.language == "en" && <PrivacyPolicyEn />}
            </div>
          </div>
        </div>
      </div>
      <div className="cs-height_60 cs-height_lg_30"></div>
    </>
  );
}
export default connect((state) => {
  return {
    language: state.language,
  };
})(PrivacyPolicy);
