import BInput from "@/components/b-input.jsx"
import BSelect from "@/components/b-select.jsx"
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import http from "@/http";
export default connect((state) => {
  return {
    userInfo: state.userInfo,
    language: state.language,
  };
})(({ step, formData, updateForm, userInfo }) => {
  useEffect(() => {
    updateForm(() => {
      formData.createBy = userInfo.id
    })()
  }, [])
  let nums = [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 30, value: 30 },
    { label: 40, value: 40 },
  ]
  const checkFile = () => {
    $('<input type="file" accept=".jpg,.gif,.png,.hwp,.ppt,.pptx" multiple/>')
      .change(function (event) {
        let form = new FormData();
        form.append("files", this.files);
        http
          .post("/common/uploads", form, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            updateForm(() => {
              console.log(res)
              // formData.nftUrls.push(res.url)
            })()
          });
      })
      .trigger("click");
  };
  return <>
    <div className="big-upload">
      <img className="img" src="http://nft.patentnftx.com/bigtech/image/US10741183_1003.png" alt="" />
      <div className="d-flex justify-content-between">
        <div>
          <button className="upload-btn" onClick={checkFile}><span>Upload</span></button>
          &nbsp;
          <span>23개 파일 업로드 완료</span>
        </div>
        <div className="d-flex align-items-center pointer" onClick={updateForm(() => formData.nftUrls = [])}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66797 12C2.3013 12 1.98741 11.8694 1.7263 11.6083C1.46519 11.3472 1.33464 11.0333 1.33464 10.6667V2H0.667969V0.666667H4.0013V0H8.0013V0.666667H11.3346V2H10.668V10.6667C10.668 11.0333 10.5374 11.3472 10.2763 11.6083C10.0152 11.8694 9.7013 12 9.33463 12H2.66797ZM9.33463 2H2.66797V10.6667H9.33463V2ZM4.0013 9.33333H5.33464V3.33333H4.0013V9.33333ZM6.66797 9.33333H8.0013V3.33333H6.66797V9.33333Z" fill="#494F6A" />
          </svg>
          &nbsp;
          Delete
        </div>
      </div>
      <div>
        <div className="title">지원되는 파일 형식</div>
        <div className="tip">JPG, PNG, GIF, SVG, MP4，다양한 유형의 파일에 대한 치수 요구사항은 다음과 같습니다.
          아바타 (320 x 320 px), 가로 (1080 x 566 px), 세로 (1080 x 1350 px), 사각형 (1080 x 1080 px)</div>
      </div>
    </div>
    &nbsp;
    <h4 className="h-title">NFT 메타 데이터</h4>
    <div className="item">
      <div className="label">NFT 판매유형<b className="cs-form-item_must">*</b></div>
      <div className="d-flex tab-nav">
        <div className={"tab-item " + (formData.nftSaleType == 1 ? 'active' : '')} >
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.8346 19.8335C20.3207 19.8335 20.7339 19.6634 21.0742 19.3231C21.4145 18.9828 21.5846 18.5696 21.5846 18.0835C21.5846 17.5974 21.4145 17.1842 21.0742 16.8439C20.7339 16.5036 20.3207 16.3335 19.8346 16.3335C19.3485 16.3335 18.9353 16.5036 18.5951 16.8439C18.2548 17.1842 18.0846 17.5974 18.0846 18.0835C18.0846 18.5696 18.2548 18.9828 18.5951 19.3231C18.9353 19.6634 19.3485 19.8335 19.8346 19.8335ZM19.8346 23.3335C20.4374 23.3335 20.9916 23.1925 21.4971 22.9106C22.0027 22.6286 22.411 22.2543 22.7221 21.7877C22.2944 21.5349 21.8374 21.3404 21.3513 21.2043C20.8652 21.0682 20.3596 21.0002 19.8346 21.0002C19.3096 21.0002 18.8041 21.0682 18.318 21.2043C17.8319 21.3404 17.3749 21.5349 16.9471 21.7877C17.2582 22.2543 17.6666 22.6286 18.1721 22.9106C18.6777 23.1925 19.2319 23.3335 19.8346 23.3335ZM14.0013 25.6668C11.2985 24.9863 9.06727 23.4356 7.30755 21.0147C5.54783 18.5939 4.66797 15.9057 4.66797 12.9502V5.8335L14.0013 2.3335L23.3346 5.8335V12.4543C22.9652 12.2988 22.586 12.1578 22.1971 12.0314C21.8082 11.905 21.4096 11.8127 21.0013 11.7543V7.46683L14.0013 4.84183L7.0013 7.46683V12.9502C7.0013 13.8641 7.12283 14.7779 7.36589 15.6918C7.60894 16.6057 7.94922 17.4759 8.38672 18.3022C8.82422 19.1286 9.35408 19.8918 9.9763 20.5918C10.5985 21.2918 11.2888 21.8752 12.0471 22.3418C12.261 22.9641 12.543 23.5571 12.893 24.121C13.243 24.6849 13.6416 25.1904 14.0888 25.6377C14.0694 25.6377 14.0548 25.6425 14.0451 25.6522C14.0353 25.662 14.0207 25.6668 14.0013 25.6668ZM19.8346 25.6668C18.2207 25.6668 16.8451 25.0981 15.7076 23.9606C14.5701 22.8231 14.0013 21.4474 14.0013 19.8335C14.0013 18.2196 14.5701 16.8439 15.7076 15.7064C16.8451 14.5689 18.2207 14.0002 19.8346 14.0002C21.4485 14.0002 22.8242 14.5689 23.9617 15.7064C25.0992 16.8439 25.668 18.2196 25.668 19.8335C25.668 21.4474 25.0992 22.8231 23.9617 23.9606C22.8242 25.0981 21.4485 25.6668 19.8346 25.6668Z" fill="currentColor" />
          </svg>
          &nbsp;
          후원
        </div>
        <div className={"tab-item " + (formData.nftSaleType == 2 ? 'active' : '')} >
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2888 15.9835L12.3096 12.6585L9.6263 10.5002H12.9513L14.0013 7.2335L15.0513 10.5002H18.3763L15.6638 12.6585L16.6846 15.9835L14.0013 13.9127L11.2888 15.9835ZM7.0013 26.8335V17.821C6.26241 17.0043 5.6888 16.071 5.28047 15.021C4.87214 13.971 4.66797 12.8529 4.66797 11.6668C4.66797 9.06127 5.57214 6.85433 7.38047 5.046C9.1888 3.23766 11.3957 2.3335 14.0013 2.3335C16.6069 2.3335 18.8138 3.23766 20.6221 5.046C22.4305 6.85433 23.3346 9.06127 23.3346 11.6668C23.3346 12.8529 23.1305 13.971 22.7221 15.021C22.3138 16.071 21.7402 17.0043 21.0013 17.821V26.8335L14.0013 24.5002L7.0013 26.8335ZM14.0013 18.6668C15.9457 18.6668 17.5985 17.9863 18.9596 16.6252C20.3207 15.2641 21.0013 13.6113 21.0013 11.6668C21.0013 9.72238 20.3207 8.06961 18.9596 6.7085C17.5985 5.34738 15.9457 4.66683 14.0013 4.66683C12.0569 4.66683 10.4041 5.34738 9.04297 6.7085C7.68186 8.06961 7.0013 9.72238 7.0013 11.6668C7.0013 13.6113 7.68186 15.2641 9.04297 16.6252C10.4041 17.9863 12.0569 18.6668 14.0013 18.6668ZM9.33464 23.3627L14.0013 22.1668L18.668 23.3627V19.746C17.9874 20.1349 17.2534 20.4411 16.4659 20.6647C15.6784 20.8884 14.8569 21.0002 14.0013 21.0002C13.1457 21.0002 12.3242 20.8884 11.5367 20.6647C10.7492 20.4411 10.0152 20.1349 9.33464 19.746V23.3627Z" fill="currentColor" />
          </svg>
          &nbsp;
          특허
        </div>
      </div>
    </div>
    <div className="item">
      <div className="label">소유자</div>
      <div className="d-flex align-items-center">
        <img className="avatar me-2" src={userInfo.avatar} alt="" />
        {userInfo.nickName}
      </div>
    </div>
    <div className="item">
      <div className="label">컬렉션명<b className="cs-form-item_must">*</b></div>
      <BInput value={formData.collectionName} onChange={updateForm((ev) => formData.collectionName = ev.target.value)} disabled={true} />
    </div>
    <div className="item">
      <div className="label">NFT명<b className="cs-form-item_must">*</b></div>
      <BInput value={formData.nftName} onChange={updateForm((ev) => formData.nftName = ev.target.value)} />
    </div>
    <div className="item">
      <div className="label">NFT 발행개수<b className="cs-form-item_must">*</b></div>
      <div className="d-flex gap-1 align-items-center">
        <BSelect className="flex-fill" list={nums} value={formData.collectionNumber} onChange={updateForm((val) => formData.collectionNumber = val)} disabled={formData.nftSaleType == 2} />
        <span>개</span>
      </div>
    </div>
    <div className="item">
      <div className="label">NFT설명<b className="cs-form-item_must">*</b></div>
      <textarea className="textarea" value={formData.collectionNotes} onChange={updateForm((ev) => formData.collectionNotes = ev.target.value)}></textarea>
    </div>
    <div className="d-flex gap-3">
      <button className="cs-btn cs-style1 cs-btn_lg flex-fill hollow" onClick={() => step[1](1)}><span className="bg-text">이전</span></button>
      <button className="cs-btn cs-style1 cs-btn_lg flex-fill" onClick={() => step[1](3)}><span>다음</span></button>
    </div>
  </>
})