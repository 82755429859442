export default () => <>
  <div className="cs-card cs-style4 cs-white_bg card-1">
    <span className="cs-card_like cs-primary_color">
      <i className="fas fa-heart fa-fw">
      </i>2.1K</span>
    <a className="cs-card_thumb cs-zoom_effect">
      <img src="http://nft.patentnftx.com/nvd/image/image_1.jpg" alt="Image" height="283" className="cs-zoom_item" />
    </a>
    <div className="cs-card_info">
      <a href="#" className="cs-avatar">
        <img src="/assets/img/avatar/avatar_12.png" alt="Avatar" />
        <span>Johny E.</span>
      </a>
      <h3 className="cs-card_title">
        <a>Data-to-energy Inverter#1008</a>
      </h3>
      <div className="cs-card_price">가격: <b className="cs-primary_color">1000 BPT</b>
      </div>
      <hr />
      <div className="cs-card_footer">
        <span className="cs-card_btn_1" data-modal="#history_1">
        </span>
        <a className="cs-card_btn_2" >
          <span>구매</span>
        </a>
      </div>
    </div>
  </div>
</>