import Card from "@/components/card.jsx"
import PagingBtn from "@/components/paging-btn.jsx"
import "@/assets/sass/pantent-list.scss"
function PatentList() {
  return (<div className="patent-list">
    <div className="cs-height_90 cs-height_lg_80"></div>
    {/* Start Page Head */}
    <section className="cs-page_head cs-bg" data-src="/assets/img/patentlistheadbg.jpg" style={{ height: "270px" }}>
      <div className="container">

      </div>
    </section>
    {/* End Page Head */}
    <div className="container">
      <div className="d-flex user-info align-items-center">
        <img className="avatar" src="/assets/img/patentlistheadbg.jpg"></img>
        <div className="flex-fill">
          <div className="name">Edward Figarp</div>
          <div className="tip">특허 NFT를 통해 창작을 사랑하고, 우호적인 상호작용을 통해 일상에서 영감을 받아 따뜻함과 꿈을 전하고자 합니다.</div>
          <div className="d-flex">
            <div className="flex-fill d-flex">
              <div className="d-flex align-items-center item">
                <span className="label">좋아요</span>
                <span className="val">252.1K</span>
              </div>
              <div className="d-flex align-items-center item">
                <span className="label">판매된 NFT</span>
                <span className="val">50+</span>
              </div>
              <div className="d-flex align-items-center item">
                <span className="label">팔로우</span>
                <span className="val">3416</span>
              </div>
            </div>
            <div className="d-flex ">
              <button className="notice me-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 10C2 8.33335 2.37083 6.80418 3.1125 5.41251C3.85417 4.02085 4.85 2.86668 6.1 1.95001L7.275 3.55001C6.275 4.28335 5.47917 5.20835 4.8875 6.32501C4.29583 7.44168 4 8.66668 4 10H2ZM20 10C20 8.66668 19.7042 7.44168 19.1125 6.32501C18.5208 5.20835 17.725 4.28335 16.725 3.55001L17.9 1.95001C19.15 2.86668 20.1458 4.02085 20.8875 5.41251C21.6292 6.80418 22 8.33335 22 10H20ZM4 19V17H6V10C6 8.61668 6.41667 7.38751 7.25 6.31251C8.08333 5.23751 9.16667 4.53335 10.5 4.20001V3.50001C10.5 3.08335 10.6458 2.72918 10.9375 2.43751C11.2292 2.14585 11.5833 2.00001 12 2.00001C12.4167 2.00001 12.7708 2.14585 13.0625 2.43751C13.3542 2.72918 13.5 3.08335 13.5 3.50001V4.20001C14.8333 4.53335 15.9167 5.23751 16.75 6.31251C17.5833 7.38751 18 8.61668 18 10V17H20V19H4ZM12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1958 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22ZM8 17H16V10C16 8.90001 15.6083 7.95835 14.825 7.17501C14.0417 6.39168 13.1 6.00001 12 6.00001C10.9 6.00001 9.95833 6.39168 9.175 7.17501C8.39167 7.95835 8 8.90001 8 10V17Z" fill="#3772FF" />
                </svg>
              </button>
              <button className="btn-plain me-2"> <span className="bg-text">내 NFT관리</span></button>
              <button className="btn-entity">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.16667 13.8333H3.35417L11.5 5.6875L10.3125 4.5L2.16667 12.6458V13.8333ZM0.5 15.5V11.9583L11.5 0.979167C11.6667 0.826389 11.8507 0.708333 12.0521 0.625C12.2535 0.541667 12.4653 0.5 12.6875 0.5C12.9097 0.5 13.125 0.541667 13.3333 0.625C13.5417 0.708333 13.7222 0.833333 13.875 1L15.0208 2.16667C15.1875 2.31944 15.309 2.5 15.3854 2.70833C15.4618 2.91667 15.5 3.125 15.5 3.33333C15.5 3.55556 15.4618 3.76736 15.3854 3.96875C15.309 4.17014 15.1875 4.35417 15.0208 4.52083L4.04167 15.5H0.5ZM10.8958 5.10417L10.3125 4.5L11.5 5.6875L10.8958 5.10417Z" fill="white" />
                </svg>
                &nbsp;
                편집
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="cs-height_60 cs-height_lg_60"></div>
    <div className="container">
      <div className="cs-tabs cs-fade_tabs">
        <ul className="cs-tab_links cs-style1  cs-primary_color cs-mp0">
          <li className="active">
            <a href="#tab1"><span className="txt">발행된 NFT</span><span className="dage">302</span></a>
          </li>
          <li>
            <a href="#tab2"><span className="txt">판매중</span><span className="dage">67</span></a>
          </li>
          <li>
            <a href="#tab3"><span className="txt">이벤트</span><span className="dage">3</span></a>
          </li>
        </ul>
        <div className="cs-height_20 cs-height_lg_20"></div>
        <div className="cs-tab_content">
          <div className="cs-tab active" id="tab1">
            <div className="row ">
              {Array(20).fill('').map((item, idx) => <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4" key={idx}>
                <Card></Card>
              </div>)}
            </div>
            <PagingBtn />
          </div>
          <div className="cs-tab" id="tab2">
            <div className="row">
              {Array(20).fill('').map((item, idx) => <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4" key={idx}>
                <Card></Card>
              </div>)}
            </div>
            <PagingBtn />
          </div>
          <div className="cs-tab" id="tab3">
            <div className="row ">
              {Array(20).fill('').map((item, idx) => <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4" key={idx}>
                <Card></Card>
              </div>)}
            </div>
            <PagingBtn />
          </div>
        </div>
      </div>
    </div>
  </div>)
}
export default PatentList;