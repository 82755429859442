export default () => (
  <>
    <div className="text-center mb-4">
      <h3 className="idea-title">아이디어 챌린지</h3>
      <br />
      <p className="idea-tip">전기차 폐배터리의 재사용 ∙ 재활용</p>
    </div>
    <table className="table">
      <tr>
        <td className="label">런칭 이벤트</td><td > <b style={{ fontSize: "15.6px", letterSpacing: "-1px" }}>전기차 폐배터리의 재사용·재활용에 대한 혁신적이고 창의적인 아이디어를 모집합니다!</b></td>
      </tr>
      <tr>
        <td className="label">일정 공모접수</td><td>2024년 9월 1일 ~ 2024년 11월 30일 (3개월)</td>
      </tr>
      <tr>
        <td className="label">공모주제</td>
        <td>
          <p>
            폐배터리 재사용 아이디어
          </p>
          <ul>
            <li>폐배터리를 활용한 새로운 제품 개발</li>
            <li>기존 제품에 폐배터리를 적용하여 성능 개선</li>
          </ul>
          <p>
            폐배터리 재사용 아이디어
          </p>
          <ul>
            <li>폐배터리를 활용한 새로운 제품 개발</li>
            <li>기존 제품에 폐배터리를 적용하여 성능 개선</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td className="label">응모방법</td><td><p>BPT 플랫폼에서 회원 가입</p><p>아이디어 기본정보 작성 및 파일 첨부 후 제출</p></td>
      </tr>
      <tr>
        <td className="label">심사방법</td>
        <td><p>특허전문가들의 서면 평가를 통한 심사</p><p className="tip">심사기준</p>
          <table>
            <thead>
              <tr>
                <th>지표</th>
                <th>배점</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>창의성</td>
                <td>30</td>
                <td>독창적이고 혁신적인 아이디어인가?</td>
              </tr>
              <tr>
                <td>실현가능성</td>
                <td>30</td>
                <td>기술적, 경제적으로 실현 가능한가?</td>
              </tr>
              <tr>
                <td>지속가능성</td>
                <td>30</td>
                <td>환경에 긍정적인 영향을 미치는가?</td>
              </tr>
              <tr>
                <td>사회영향력</td>
                <td>10</td>
                <td>사회에 미치는 긍정적인 효과가 큰가?</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td className="label">심사방법</td>
        <td>
          <p className="tip">심사기준</p>
          <table>
            <thead>
              <tr>
                <th>상급</th>
                <th>시상규모</th>
                <th>수상혜택</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>대상</td>
                <td>1인</td>
                <td>1,000BPT 지급, AI 특허명세서 이용권</td>
              </tr>
              <tr>
                <td>최우수</td>
                <td>1인</td>
                <td>800BPT 지급</td>
              </tr>
              <tr>
                <td>우수상</td>
                <td>2인</td>
                <td>500BPT 지급</td>
              </tr>
              <tr>
                <td>장려상</td>
                <td>3인</td>
                <td>300BPT 지급</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td className="label">문의처</td>
        <td>patentnft@patent-nft.com</td>
      </tr>
      <tr>
        <td className="label">기타 사항</td>
        <td>
          <ul>
            <li>적합한 아이디어가 없는 경우 선정하지 않을 수 있습니다.</li>
            <li>제출된 아이디어에 대한 소유권은 제출자에게 있습니다. 단, 기업 소속으로 아이디어를 제출하실 경우 기업과 제출자 간 소유권 문제가 없도록 유의하시기 바랍니다.</li>
            <li>수상작은 주최 측의 판단에 따라 실용화될 수 있으며, 이 과정에서 추가적인 협력이 필요할 수 있습니다.</li>
            <li>참가자는 응모와 동시에 본 공모전의 모든 조건에 동의한 것으로 간주됩니다.</li>
          </ul>
        </td>
      </tr>
    </table>
  </>
);
