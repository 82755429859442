import { useTranslation } from "react-i18next";
import MineLayout from "@/components/mine-layout.jsx";
import http from "../http";
import { message } from "antd";
import { useEffect, useState } from "react";
export default function UserAccountSettings() {
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  let [pas, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  let setPas = (key) => (event) => {
    pas[key] = event.target.value;
    setPassword({ ...pas });
  };
  let submit = () => {
    if (pas.newPassword != pas.confirmPassword) {
      messageApi.error("비밀번호가 두 번 일치하지 않습니다.");
      return;
    }
    http
      .put(
        `/system/user/profile/updatePwd?newPassword=${pas.newPassword}&oldPassword=${pas.oldPassword}`,
        pas
      )
      .then((res) => { })
      .catch((res) => { });
  };
  return (
    <>
      {contextHolder}
      <MineLayout>
        <div className="cs-profile_right">
          <div className="cs-height_30 cs-height_lg_30"></div>
          <h2 className="cs-section_heading cs-style1">
            {t("userAccountSettings.account_settings")}
          </h2>
          <div className="cs-height_25 cs-height_lg_25"></div>
          <form className="row">
            <div className="col-lg-6 mx-auto">
              <div className="cs-form_field_wrap">
                <input
                  type="password"
                  className="cs-form_field cs-white_bg"
                  placeholder={t("userAccountSettings.previous_password")}
                  value={pas.oldPassword}
                  onChange={setPas("oldPassword")}
                />
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
              <div className="cs-form_field_wrap">
                <input
                  type="password"
                  className="cs-form_field cs-white_bg"
                  placeholder={t("userAccountSettings.new_password")}
                  value={pas.newPassword}
                  onChange={setPas("newPassword")}
                />
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
              <div className="cs-form_field_wrap">
                <input
                  type="password"
                  className="cs-form_field cs-white_bg"
                  placeholder={t("userAccountSettings.confirm_new_password")}
                  value={pas.confirmPassword}
                  onChange={setPas("confirmPassword")}
                />
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
              <div className="d-flex justify-content-end">
                <button className="cs-btn cs-style1 cs-btn_lg " onClick={submit}>
                  <span>{t("userAccountSettings.modify_password")}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </MineLayout>
    </>
  );
}
