import { useWallet } from "../WalletContext";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import store from "@/store";
import http from "@/http";
import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import HeaderNotice from "./header-notice";
import "@/assets/sass/header.scss"
function navHeader(props) {
  const { t } = useTranslation();
  const { wallet, connectWallet, disconnectWallet, clearAppState } =
    useWallet();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [ko, setKo] = useState(false);
  const toLogin = () => navigate("/login");
  const loginOut = () => {
    http.post("/logout").then((res) => {
      store.dispatch({ type: "logout" });
      toLogin();
      // window.location.reload();
    });
  };
  const onClickConnectWallet = () => {
    setKo(localStorage.getItem("language") == "ko");
    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isMobile) {
      setOpen(true);
    } else {
      connectWallet();
    }
  };
  const userBtn = useRef();
  let closeUserBtn = () => {
    $(userBtn.current).trigger("click");
  };

  const languageBtn = useRef();
  let closeLanguageBtn = () => {
    $(languageBtn.current).trigger("click");
  };
  const noticeBtn = useRef()
  let closeNoticeBtn = () => {
    $(noticeBtn.current).trigger("click");
  }
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  const handleOk = () => {
    connectWallet();
    setOpen(false);
  };

  useEffect(() => {
    $(window).on("click", () => {
      $("#header .cs-toggle_btn.active").trigger("click");
    });
  }, []);
  return (
    <header
      id="header"
      className="cs-site_header cs-style1 cs-sticky-header cs-white_bg"
      onClick={(event) => event.stopPropagation()}
    >
      <Modal
        title="Warning"
        open={open}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        {ko ? (
          <p>
            지금 모바일 브라우저에서 지갑을 연결하려고 하십니다. 메타마스크 앱의
            브라우저에서 이 웹사이트를 열고 지갑을 연결하세요.
          </p>
        ) : (
          <p>
            You are connecting the wallet in mobile browser. Please open this
            websit in the browser of your metamask app and connect the wallet.
          </p>
        )}
      </Modal>
      <div className="cs-main_header">
        <div className="container-fluid">
          <div className="cs-main_header_in">
            <div className="cs-main_header_left">
              <a className="cs-site_branding" href="/">
                <img src="/assets/img/logo.png" alt="Logo" />
              </a>
            </div>
            <div className="cs-main_header_right">
              <div className="cs-nav_wrap">
                <div className="cs-nav_out">
                  <div className="cs-nav_in">
                    <div className="cs-nav">
                      <ul className="cs-nav_list  GmarketSans weight400">
                        <li>
                          <a href="/">{t("components.home")}</a>
                        </li>
                        <li>
                          <a href="#/bigtech">{t("components.artwork")}</a>
                        </li>
                        <li>
                          <a href="#/general">{t("components.patent")} NFT</a>
                        </li>
                        <li>
                          <a href="#/idea">{t("components.idea_challenge")}</a>
                        </li>
                        <li>
                          <a href="#/contact">
                            {t("components.customer_inquiry")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cs-header_btns_wrap">

                <div className="cs-header_btns">
                  {false && <div className="cs-toggle_box cs-notice_box">
                    <div className="cs-toggle_btn cs-header_icon_btn cs-center" ref={noticeBtn}>
                      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.66602 22.1673V19.834H6.99935V11.6673C6.99935 10.0534 7.48546 8.6194 8.45768 7.36523C9.4299 6.11107 10.6938 5.28954 12.2493 4.90065V4.08398C12.2493 3.59787 12.4195 3.18468 12.7598 2.8444C13.1 2.50412 13.5132 2.33398 13.9993 2.33398C14.4855 2.33398 14.8987 2.50412 15.2389 2.8444C15.5792 3.18468 15.7493 3.59787 15.7493 4.08398V4.90065C17.3049 5.28954 18.5688 6.11107 19.541 7.36523C20.5132 8.6194 20.9993 10.0534 20.9993 11.6673V19.834H23.3327V22.1673H4.66602ZM13.9993 25.6673C13.3577 25.6673 12.8084 25.4388 12.3514 24.9819C11.8945 24.525 11.666 23.9756 11.666 23.334H16.3327C16.3327 23.9756 16.1042 24.525 15.6473 24.9819C15.1903 25.4388 14.641 25.6673 13.9993 25.6673ZM9.33268 19.834H18.666V11.6673C18.666 10.384 18.2091 9.28537 17.2952 8.37148C16.3813 7.4576 15.2827 7.00065 13.9993 7.00065C12.716 7.00065 11.6174 7.4576 10.7035 8.37148C9.78963 9.28537 9.33268 10.384 9.33268 11.6673V19.834Z" fill="#2B2B2B" />
                      </svg>
                    </div>
                    <div className="cs-toggle_body">
                      {/* 通知 */}
                      <HeaderNotice />
                    </div>
                  </div>}

                  <div className="cs-toggle_box cs-notification_box">
                    <div
                      className="cs-toggle_btn cs-header_icon_btn cs-center"
                      ref={languageBtn}
                    >
                      <img src="/assets/img/btn_language.png" alt="" />
                    </div>
                    <div className="cs-toggle_body">
                      <ul className="cs-notification_list">
                        <li
                          className="cs-notification_item"
                          onClick={() => {
                            store.dispatch({
                              type: "changeLanguage",
                              language: "ko",
                            });
                            closeLanguageBtn();
                          }}
                        >
                          한국어
                        </li>
                        <li
                          className="cs-notification_item"
                          onClick={() => {
                            store.dispatch({
                              type: "changeLanguage",
                              language: "en",
                            });
                            closeLanguageBtn();
                          }}
                        >
                          English
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="cs-toggle_box cs-profile_box"
                    style={{ display: props.isLogin ? "block" : "none" }}
                  >
                    <div
                      className="cs-toggle_btn cs-header_icon_btn cs-center"
                      ref={userBtn}
                    >
                      <img
                        style={{ width: 28, height: 28 }}
                        src="/assets/img/profile.jpg"
                        alt=""
                      />
                    </div>
                    <div className="cs-toggle_body cs-user_info_box">
                      <div className="cs-user_info">
                        <h3 className="cs-nick_name">
                          {props.userInfo?.nickName}
                        </h3>
                        <div className="cs-user_name">
                          {props.userInfo?.userName}
                        </div>
                        {/* <h4 className="cs-user_balance">
                          {wallet?.balance || 0}ETH
                        </h4> */}
                        {/* <div className="d-flex justify-content-between balance-list text-center">
                          <div className="item">
                            <div className="val">756</div>
                            <div className="label">좋아요</div>
                          </div>
                          <div className="item">
                            <div className="val">756</div>
                            <div className="label">판매된NFT</div>
                          </div>
                          <div className="item">
                            <div className="val">756</div>
                            <div className="label">팔로우</div>
                          </div>
                        </div> */}
                        {/* <div className="cs-user_profile_link">
                          {!!wallet?.account && (
                            <div className="cs-user_profile_link_in">
                              <span className="text-ellipsis d-block">
                                {wallet?.account}
                              </span>
                              <button>
                                <svg
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.35381 4.15531L4.35156 5.74756V13.6256C4.35156 14.272 4.60837 14.892 5.06549 15.3491C5.52261 15.8063 6.1426 16.0631 6.78906 16.0631H13.2511C13.1346 16.3921 12.9191 16.6769 12.634 16.8784C12.349 17.0799 12.0086 17.1881 11.6596 17.1881H6.78906C5.84423 17.1881 4.93809 16.8127 4.26999 16.1446C3.6019 15.4765 3.22656 14.5704 3.22656 13.6256V5.74756C3.22656 5.01256 3.69681 4.38631 4.35381 4.15531ZM13.5391 2.18506C13.7607 2.18506 13.9801 2.22871 14.1848 2.31351C14.3896 2.39832 14.5756 2.52262 14.7323 2.67932C14.889 2.83601 15.0133 3.02204 15.0981 3.22678C15.1829 3.43152 15.2266 3.65095 15.2266 3.87256V13.6226C15.2266 13.8442 15.1829 14.0636 15.0981 14.2683C15.0133 14.4731 14.889 14.6591 14.7323 14.8158C14.5756 14.9725 14.3896 15.0968 14.1848 15.1816C13.9801 15.2664 13.7607 15.3101 13.5391 15.3101H6.78906C6.34151 15.3101 5.91229 15.1323 5.59582 14.8158C5.27935 14.4993 5.10156 14.0701 5.10156 13.6226V3.87256C5.10156 3.42501 5.27935 2.99578 5.59582 2.67932C5.91229 2.36285 6.34151 2.18506 6.78906 2.18506H13.5391ZM13.5391 3.31006H6.78906C6.63988 3.31006 6.4968 3.36932 6.39132 3.47481C6.28583 3.5803 6.22656 3.72337 6.22656 3.87256V13.6226C6.22656 13.9331 6.47856 14.1851 6.78906 14.1851H13.5391C13.6882 14.1851 13.8313 14.1258 13.9368 14.0203C14.0423 13.9148 14.1016 13.7717 14.1016 13.6226V3.87256C14.1016 3.72337 14.0423 3.5803 13.9368 3.47481C13.8313 3.36932 13.6882 3.31006 13.5391 3.31006Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                        </div> */}
                      </div>
                      <ul className="cs-header_collspan_nav">
                        <li>
                          <a onClick={closeUserBtn} href="#/user-profile">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.9987 9.9987C9.08203 9.9987 8.29731 9.67231 7.64453 9.01953C6.99175 8.36675 6.66536 7.58203 6.66536 6.66536C6.66536 5.7487 6.99175 4.96398 7.64453 4.3112C8.29731 3.65842 9.08203 3.33203 9.9987 3.33203C10.9154 3.33203 11.7001 3.65842 12.3529 4.3112C13.0056 4.96398 13.332 5.7487 13.332 6.66536C13.332 7.58203 13.0056 8.36675 12.3529 9.01953C11.7001 9.67231 10.9154 9.9987 9.9987 9.9987ZM3.33203 16.6654V14.332C3.33203 13.8598 3.45356 13.4258 3.69661 13.0299C3.93967 12.6341 4.26259 12.332 4.66536 12.1237C5.52648 11.6931 6.40148 11.3702 7.29036 11.1549C8.17925 10.9397 9.08203 10.832 9.9987 10.832C10.9154 10.832 11.8181 10.9397 12.707 11.1549C13.5959 11.3702 14.4709 11.6931 15.332 12.1237C15.7348 12.332 16.0577 12.6341 16.3008 13.0299C16.5438 13.4258 16.6654 13.8598 16.6654 14.332V16.6654H3.33203ZM4.9987 14.9987H14.9987V14.332C14.9987 14.1793 14.9605 14.0404 14.8841 13.9154C14.8077 13.7904 14.707 13.6931 14.582 13.6237C13.832 13.2487 13.0751 12.9674 12.3112 12.7799C11.5473 12.5924 10.7765 12.4987 9.9987 12.4987C9.22092 12.4987 8.45009 12.5924 7.6862 12.7799C6.92231 12.9674 6.16536 13.2487 5.41536 13.6237C5.29036 13.6931 5.18967 13.7904 5.11328 13.9154C5.03689 14.0404 4.9987 14.1793 4.9987 14.332V14.9987ZM9.9987 8.33203C10.457 8.33203 10.8494 8.16884 11.1758 7.84245C11.5022 7.51606 11.6654 7.1237 11.6654 6.66536C11.6654 6.20703 11.5022 5.81467 11.1758 5.48828C10.8494 5.16189 10.457 4.9987 9.9987 4.9987C9.54036 4.9987 9.148 5.16189 8.82161 5.48828C8.49523 5.81467 8.33203 6.20703 8.33203 6.66536C8.33203 7.1237 8.49523 7.51606 8.82161 7.84245C9.148 8.16884 9.54036 8.33203 9.9987 8.33203Z" fill="#494F6A" />
                            </svg>
                            &nbsp;
                            {t("components.my_profile")}
                          </a>
                        </li>
                        <li>
                          <a onClick={closeUserBtn}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.83333 14.1667H7.5V10H5.83333V14.1667ZM12.5 14.1667H14.1667V5.83333H12.5V14.1667ZM9.16667 14.1667H10.8333V11.6667H9.16667V14.1667ZM9.16667 10H10.8333V8.33333H9.16667V10ZM4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM4.16667 15.8333H15.8333V4.16667H4.16667V15.8333Z" fill="#494F6A" />
                            </svg>
                            &nbsp;
                            {t('mine.nft_application')}
                          </a>
                        </li>
                        <li>
                          <a onClick={closeUserBtn} href="#/user-nfts">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.83333 14.1667H7.5V10H5.83333V14.1667ZM12.5 14.1667H14.1667V5.83333H12.5V14.1667ZM9.16667 14.1667H10.8333V11.6667H9.16667V14.1667ZM9.16667 10H10.8333V8.33333H9.16667V10ZM4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM4.16667 15.8333H15.8333V4.16667H4.16667V15.8333Z" fill="#494F6A" />
                            </svg>
                            &nbsp;
                            {t('components.owned_nft')}
                          </a>
                        </li>
                        <li>
                          <a onClick={closeUserBtn} href="#/user-wallet">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V6.25H15.8333V4.16667H4.16667V15.8333H15.8333V13.75H17.5V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM10.8333 14.1667C10.375 14.1667 9.98264 14.0035 9.65625 13.6771C9.32986 13.3507 9.16667 12.9583 9.16667 12.5V7.5C9.16667 7.04167 9.32986 6.64931 9.65625 6.32292C9.98264 5.99653 10.375 5.83333 10.8333 5.83333H16.6667C17.125 5.83333 17.5174 5.99653 17.8438 6.32292C18.1701 6.64931 18.3333 7.04167 18.3333 7.5V12.5C18.3333 12.9583 18.1701 13.3507 17.8438 13.6771C17.5174 14.0035 17.125 14.1667 16.6667 14.1667H10.8333ZM16.6667 12.5V7.5H10.8333V12.5H16.6667ZM13.3333 11.25C13.6806 11.25 13.9757 11.1285 14.2188 10.8854C14.4618 10.6424 14.5833 10.3472 14.5833 10C14.5833 9.65278 14.4618 9.35764 14.2188 9.11458C13.9757 8.87153 13.6806 8.75 13.3333 8.75C12.9861 8.75 12.691 8.87153 12.4479 9.11458C12.2049 9.35764 12.0833 9.65278 12.0833 10C12.0833 10.3472 12.2049 10.6424 12.4479 10.8854C12.691 11.1285 12.9861 11.25 13.3333 11.25Z" fill="#494F6A" />
                            </svg>
                            &nbsp;
                            {t('components.my_wallet')}
                          </a>
                        </li>
                        {/* <li>
                          <a onClick={closeUserBtn} >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.043 13.707L14.793 12.4154C15.3485 12.2626 15.7999 11.9674 16.1471 11.5299C16.4944 11.0924 16.668 10.582 16.668 9.9987C16.668 9.30425 16.4249 8.71398 15.9388 8.22786C15.4527 7.74175 14.8624 7.4987 14.168 7.4987H10.8346V5.83203H14.168C15.3207 5.83203 16.3034 6.23828 17.1159 7.05078C17.9284 7.86328 18.3346 8.84592 18.3346 9.9987C18.3346 10.7904 18.1298 11.5195 17.7201 12.1862C17.3103 12.8529 16.7513 13.3598 16.043 13.707ZM13.2096 10.832L11.543 9.16536H13.3346V10.832H13.2096ZM16.5013 18.832L1.16797 3.4987L2.33464 2.33203L17.668 17.6654L16.5013 18.832ZM9.16797 14.1654H5.83464C4.68186 14.1654 3.69922 13.7591 2.88672 12.9466C2.07422 12.1341 1.66797 11.1515 1.66797 9.9987C1.66797 9.04036 1.95964 8.1862 2.54297 7.4362C3.1263 6.6862 3.8763 6.19314 4.79297 5.95703L6.33464 7.4987H5.83464C5.14019 7.4987 4.54991 7.74175 4.0638 8.22786C3.57769 8.71398 3.33464 9.30425 3.33464 9.9987C3.33464 10.6931 3.57769 11.2834 4.0638 11.7695C4.54991 12.2556 5.14019 12.4987 5.83464 12.4987H9.16797V14.1654ZM6.66797 10.832V9.16536H8.02214L9.66797 10.832H6.66797Z" fill="#494F6A" />
                            </svg>
                            &nbsp;
                            {t('components.offline_transaction_request')}
                          </a>
                        </li> */}
                      </ul>
                      <div>
                        <a className="" onClick={loginOut}>
                          <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H10V4.16667H4.16667V15.8333H10V17.5H4.16667ZM13.3333 14.1667L12.1875 12.9583L14.3125 10.8333H7.5V9.16667H14.3125L12.1875 7.04167L13.3333 5.83333L17.5 10L13.3333 14.1667Z" fill="#494F6A" />
                            </svg>
                            &nbsp;
                            {t("components.logout")}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  {props.isLogin ? (
                    <>
                      {wallet.account ? (
                        <a
                          className="cs-btn cs-style1 wallter-user"
                          onClick={disconnectWallet}
                        >
                          {wallet.account}
                        </a>
                      ) : (
                        <a
                          className="cs-btn cs-style1 pointer"
                          onClick={() => onClickConnectWallet()}
                        >
                          <span>{t("components.connect_wallet")}</span>
                        </a>
                      )}
                    </>
                  ) : (
                    <a
                      className="cs-btn cs-style1 wallter-user"
                      onClick={toLogin}
                    >

                      <span>{t("components.login")}</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default connect((state) => {
  return {
    userInfo: state.userInfo,
    isLogin: state.isLogin,
    language: state.language,
  };
})(navHeader);
