import { useTranslation } from "react-i18next";
import http from "../http";
import { useEffect, useState } from "react";
import MineLayout from "@/components/mine-layout.jsx";
import { Calendar, theme } from 'antd';
export default function UserProfile() {
  const { t } = useTranslation();
  const checkFile = () => {
    $('<input type="file" accept=".jpg,.gif,.png,.hwp,.ppt,.pptx"/>')
      .change(function (event) {
        let form = new FormData();
        form.append("file", this.files[0]);
        http
          .post("/system/user/profile/avatar", form, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            setUserInfo({ ...userInfo, avatar: res.imgUrl });
            updateCall && updateCall();
          });
      })
      .trigger("click");
  };
  let updateCall;
  let [userInfo, setUserInfo] = useState({
    avatar: "/assets/img/avatar/avatar_29.png",
  });
  let [isEdit, setIsEdit] = useState(false)
  let setInfo = (key) => (event) => {
    console.log(event)
    userInfo[key] = event.target.value;
    setUserInfo({ ...userInfo });
  };
  useEffect(() => {
    getDetail();
  }, []);
  let getDetail = () => {
    http.get("/system/user/profile").then((res) => {
      setUserInfo(res);
    });
  };
  // {
  //   "name": "姓名",
  //   "nickName": "昵称",
  //   "occupation": "职业",
  //   "phonenumber": "13631409095",
  //   "email": "562054047@qq.com",
  //   "birthday":"2024-10-10",
  //   "remark": "bio"
  // }
  let submit = () => {
    http
      .put("/system/user/profile", {
        name: userInfo.userName,
        nickName: userInfo.nickName,
        remark: userInfo.remark,
        phonenumber: userInfo.phonenumber,
        email: userInfo.email,
        birthday: userInfo.birthday,
        occupation: userInfo.occupation
      })
      .then((res) => {
        getDetail();
        setIsEdit(false)
        updateCall && updateCall();
      });
  };
  let updateUserInfo = (call) => (updateCall = call);
  let [showCalendar, setShowCalendar] = useState(false)
  return (
    <>
      <MineLayout updateUserInfo={updateUserInfo}>
        <div className="cs-profile_right profile_content" onClick={(e) => { setShowCalendar(false) }}>
          <div className="cs-height_30 cs-height_lg_30"></div>
          <h2 className="cs-section_heading cs-style1">
            {t("userProfile.profile_info")}
          </h2>
          <div className="cs-height_25 cs-height_lg_25"></div>
          <div className="row">
            <div className="col-lg-6">
              <div className="cs-edit_profile">
                <div className="cs-edit_profile_img">
                  <img src={userInfo.avatar} alt="" />
                </div>
                <div className="cs-edit_profile_right">
                  <div className="cs-edit_profile_btns">
                    <a className="cs-upload_btn" onClick={checkFile}>
                      Upload
                    </a>
                    {/* <span className="cs-delete_btn">
                      <i className="far fa-trash-alt"></i> Delete
                    </span> */}
                  </div>
                  <p>
                    Images must be .png or .jpg format. Min size 200x200px
                    (avatar)
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cs-form_label">{t('mine.nickname')}</div>
              <div className="cs-form_field_wrap">
                <input
                  type="text"
                  className="cs-form_field cs-white_bg"
                  placeholder=""
                  disabled={!isEdit}
                  value={userInfo.nickName}
                  onChange={setInfo('nickName')}
                />
              </div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-form_label">{t('mine.occupation')}</div>
              <div className="cs-form_field_wrap">
                <input
                  type="text"
                  className="cs-form_field cs-white_bg"
                  placeholder=""
                  disabled={!isEdit}
                  value={userInfo.occupation}
                  onChange={setInfo('occupation')}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cs-height_25 cs-height_lg_25"></div>
              <div className="d-flex gap-3">
                <div className="flex-fill">
                  <div className="cs-form_label">{t('mine.full_name')}</div>
                  <div className="cs-form_field_wrap">
                    <input type="text" className="cs-form_field cs-white_bg" placeholder="" disabled={!isEdit} value={userInfo.name} onChange={setInfo('name')} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cs-height_25 cs-height_lg_25"></div>
              <div className="cs-form_label">{t('mine.phone_number')}</div>
              <div className="cs-form_field_wrap">
                <input type="text" className="cs-form_field cs-white_bg" placeholder="" disabled={!isEdit} value={userInfo.phonenumber} onChange={setInfo('phonenumber')} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cs-height_25 cs-height_lg_25"></div>
              <div className="cs-form_label">{t('mine.date_of_birth')}</div>
              <div className="d-flex gap-1 align-items-center">
                <div className="flex-fill">
                  <div className="cs-form_field_wrap position-relative" onClick={(e) => e.stopPropagation()}>
                    <input type="text" onClick={(e) => setShowCalendar(true)} disabled={!isEdit} value={userInfo.birthday} onChange={() => { }} className="cs-form_field cs-white_bg" placeholder="" />
                    {showCalendar && <div className="position-absolute shadow top-100 calendar">
                      <Calendar fullscreen={false} onSelect={(e, value) => setInfo('birthday')({ target: { value: e.format('YYYY-MM-DD') } })} />
                    </div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cs-height_25 cs-height_lg_25"></div>
              <div className="cs-form_label">{t('mine.email')}</div>
              <div className="cs-form_field_wrap">
                <input type="text" className="cs-form_field cs-white_bg" disabled={!isEdit} value={userInfo.email} onChange={setInfo('email')} placeholder="" />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="cs-height_25 cs-height_lg_25"></div>
              <div className="cs-form_label">{t('mine.self_introduction')}</div>
              <div className="cs-form_field_wrap">
                <textarea
                  cols="30"
                  rows="5"
                  placeholder="Your bio"
                  disabled={!isEdit}
                  className="cs-form_field cs-white_bg"
                  value={userInfo.remark}
                  onChange={setInfo("remark")}
                ></textarea>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="cs-height_40 cs-height_lg_5"></div>
              {isEdit ? <button className="cs-btn cs-style1 cs-btn_lg" onClick={submit}>
                <span>{t('mine.save')}</span>
              </button> : <button className="cs-btn cs-style1 cs-btn_lg" onClick={() => setIsEdit(true)}>
                <span>{t('mine.edit')}</span>
              </button>}

            </div>
          </div>
        </div>
      </MineLayout>
    </>
  );
}
