import { useState } from "react"
export default ({ list, value, onChange, className, placeholder, disabled }) => {
  let [show, setShow] = useState(false)
  let showText = () => {
    return list?.find(item => item.value == value)?.label || value
  }
  let setVal = (item) => {
    onChange && onChange(item.value)
  }
  let showContent = () => {
    if (!show && disabled) return;
    setShow(!show)
  }
  return <>
    <div className={"b-select " + (show ? 'active ' : '') + className + ' ' + (disabled ? 'disabled ' : '')} onClick={showContent}>
      <div className="value-box">
        <div className="value">
          {showText()}
          {!showText() && !value && <span className="placeholder">{placeholder}</span>}
        </div>
        <svg width="16" className="icon" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.0151 7.43151L4.88177 10.5648L3.64844 9.33151L8.0151 4.96484L12.3818 9.33151L11.1484 10.5648L8.0151 7.43151Z" fill="#494F6A" />
        </svg>
      </div>
      <div className="content">
        <div className="select-list">
          {list?.map(item =>
            <div key={item.value} className={"select-item " + (item.value == value ? 'active' : '')} onClick={() => setVal(item)}>
              {item.label}
            </div>)}
        </div>
      </div>
    </div>
  </>
}