import axios from "axios";
import store from "@/store";
import { message } from "antd";
import i18next from "i18next";
class HttpRequest {
  constructor(responseType = "json") {
    this.timeout = 60000;
    this.headers = {
      "Content-Type": "application/json;charset=utf-8",
    };
    this.withCredentials = false;
    this.responseType = responseType;
    this.transformResponse = [
      (data) => {
        data = JSON.parse(data);
        return data;
      },
    ];
    this.instance = axios.create({
      timeout: this.timeout,
      headers: this.headers,
      withCredentials: this.withCredentials,
      responseType: this.responseType,
      transformResponse: this.transformResponse,
    });

    this.instance.interceptors.request.use(
      (config) => {
        config.headers = config.headers;
        const token =
          sessionStorage.getItem("token") || localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = token;
        }
        let { language } = store.getState();
        config.headers["Accept-Language"] = language;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  request(config) {
    store.dispatch({ type: "showLoading" });

    return new Promise((resolve, reject) => {
      this.instance
        .request(config)
        .then((response) => {
          let data = response.data;
          if (data.code === 200 || data.statusCode === 200) {
            resolve(data.data);
            if (config.method === "post" || config.method === "put") {
              message.success(data.msg);
            }
          } else if (data.code === 401) {
            message.error(data.msg || "request failure");
            window.location.href = "/#/login";
            store.dispatch({ type: "changeIsLogin", isLogin: false });
            reject(new Error(data.msg || "request failure"));
          } else if (data.statusCode === 40001) {
            message.error(i18next.t("error1") || "request failure");
            reject(new Error(i18next.t("error1") || "request failure"));
          } else if (data.statusCode === 40002) {
            message.error(i18next.t("error2") || "request failure");
            reject(new Error(i18next.t("error2") || "request failure"));
          } else if (data.statusCode === 40003) {
            message.error(i18next.t("error3") || "request failure");
            reject(new Error(i18next.t("error3") || "request failure"));
          } else {
            message.error(data.msg || "request failure");
            reject(new Error(data.msg || "request failure"));
          }
        })
        .catch((error) => {
          if (error.response?.status == 401) {
            message.error(error.response.data.msg || "request failure");
            window.location.href = "/#/login";
            store.dispatch({ type: "changeIsLogin", isLogin: false });
          }
          reject(error);
        })
        .finally(() => {
          store.dispatch({ type: "hideLoading" });
        });
    });
  }

  get(url, params, config) {
    return this.request({
      method: "get",
      url: `/api${url}`,
      params,
      ...config,
    });
  }

  post(url, data, config) {
    return this.request({
      method: "post",
      url: `/api${url}`,
      data,
      ...config,
    });
  }

  put(url, data, config) {
    return this.request({
      method: "put",
      url: `/api${url}`,
      data,
      ...config,
    });
  }

  delete(url, config) {
    return this.request({
      method: "delete",
      url: `/api${url}`,
      ...config,
    });
  }

  getnft(url, params, config) {
    return this.request({
      method: "get",
      url: `/nft${url}`,
      params,
      ...config,
    });
  }

  postnft(url, data, config) {
    return this.request({
      method: "post",
      url: `/nft${url}`,
      data,
      ...config,
    });
  }
}

export default new HttpRequest();
