import "@/assets/sass/paging.scss"
export default () => {
  return (<>
    <div className="d-flex justify-content-center paging-btn-group my-4">
      <div className="first">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="#7E85A1" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.728 12L12.668 11.06L9.61464 8L12.668 4.94L11.728 4L7.72797 8L11.728 12Z" />
          <path d="M7.33344 12L8.27344 11.06L5.2201 8L8.27344 4.94L7.33344 4L3.33344 8L7.33344 12Z" />
        </svg>
      </div>
      <div className="prev">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="#7E85A1" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.06 12L11 11.06L7.94667 8L11 4.94L10.06 4L6.06 8L10.06 12Z" />
        </svg>
      </div>
      <div className="num">1</div>
      <div className="next">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="#7E85A1" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.94 4L6 4.94L9.05333 8L6 11.06L6.94 12L10.94 8L6.94 4Z" />
        </svg>
      </div>
      <div className="last">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="#7E85A1" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.27398 4L3.33398 4.94L6.38732 8L3.33398 11.06L4.27398 12L8.27398 8L4.27398 4Z" />
          <path d="M8.66656 4L7.72656 4.94L10.7799 8L7.72656 11.06L8.66656 12L12.6666 8L8.66656 4Z" />
        </svg>
      </div>
    </div>
  </>)
}